import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import portugues from './Lng/pt'
import english from './Lng/eng'

i18n.use(LanguageDetector).init({

    resources: {

        fallbackLng: "pt",
        Lng:"pt",
        debug: true,

        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: true,

        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },

        react: {
            wait: true
        },

        pt: {
            translations: portugues
        },

        en: {
            translations: english
        }
    }
})

export default i18n;
import React, { Component } from 'react';
import './ContactForm.css'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { Bars } from 'react-loading-icons';
import ModalPopup from '../modalPopup/ModalPopup';

export class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            formErrors: { name: '', email: '', phone: '', message: '' },
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submiting: false,
            triggerPopup: false,
            error: false
        }
    }

    setInitialState() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            formErrors: { name: '', email: '', phone: '', message: '' },
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submiting: false
        }
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value) });
    }



    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let messageIsValid = this.state.messageIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = value.match(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : 'Invalid Name';
                break;
            case 'email':
                emailIsValid = value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                fieldValidationErrors.email = emailIsValid ? '' : 'Invalid Email'
                break;
            case 'phone':
                phoneIsValid = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' : 'Invalid Phone number'
                break;
            case 'message':
                messageIsValid = value.length > 10;
                fieldValidationErrors.message = messageIsValid ? '' : 'Invalid Message'
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            emailIsValid: emailIsValid,
            phoneIsValid: phoneIsValid,
            messageIsValid: messageIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.messageIsValid
        });
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submiting: true });

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/sendContactEmail/`, {
            userName: event.target[0].value,
            userPhone: event.target[2].value,
            userEmail: event.target[1].value,
            message: event.target[3].value
        }, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
            .then((res) => {
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true });
            }, (err) => {
                console.log(err.response);
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true, error: true })
            });
    }

    closePopup = () => {
        this.setState({ triggerPopup: false })
    }

    render() {
        const { t } = this.props;

        return (
            <div className="contacts-form-container">
                <form className="form-container" onSubmit={this.handleSubmit}>
                    <label className="nome-label">
                        <div className="contacts-label-title">{t('contactos.nome')} <span style={{ fontSize: "11px" }}>{t('contactos.sub-name')}</span></div>
                        <input className={`contacts-input${this.errorClass(this.state.formErrors.name)}`} name="name" placeholder="-"
                            value={this.state.name}
                            onChange={(event) => this.handleInput(event)} />
                        <p style={{ color: 'red' }}>{this.state.formErrors["name"]}</p>
                    </label>

                    <div className="phone-email-fields">
                        <label className="email-label" htmlFor="email">
                            <div className="contacts-label-title">EMAIL</div>
                            <input className={`contacts-input${this.errorClass(this.state.formErrors.email)}`} name="email" placeholder="-"
                                value={this.state.email}
                                onChange={(event) => this.handleInput(event)} />
                            <p style={{ color: 'red' }}>{this.state.formErrors["email"]}</p>
                        </label>
                        <label className="phone-label">
                            <div className="contacts-label-title">{t('contactos.phone')}</div>
                            <input className={`contacts-input${this.errorClass(this.state.formErrors.phone)}`} name="phone" placeholder="-"
                                value={this.state.phone}
                                onChange={(event) => this.handleInput(event)} />
                            <p style={{ color: 'red' }}>{this.state.formErrors["phone"]}</p>
                        </label>
                    </div>

                    <label className="message-label">
                        <div className="contacts-label-title">{t('contactos.mensagem')}</div>
                        <textarea rows={10} className={`contacts-text-area${this.errorClass(this.state.formErrors.message)}`} name="message" placeholder="-"
                            value={this.state.message}
                            onChange={(event) => this.handleInput(event)} />
                        <p style={{ color: 'red' }}>{this.state.formErrors["message"]}</p>

                    </label>

                    <div className="submitButton-Loading-div">
                        <button type="submit" disabled={!this.state.formIsValid || this.state.submiting}>{t('contactos.botão')}</button>
                        {this.state.submiting ? <Bars className="loading-symbol" /> : null}
                    </div>
                </form>

                <ModalPopup
                    trigger={this.state.triggerPopup}
                    width="400px"
                    height="400px"
                    title={this.state.error ? t('common.erro') : t('common.sucesso')}
                >
                    <div className="popup-content">
                        <div className="popup-content-text">
                            {!this.state.error ? t('contactos.popupMessage') : t('contactos.popupMessageError')}
                        </div>
                    </div>
                    <div className="popup-buttons">
                        <button className="submit-btn" onClick={this.closePopup}>OK</button>
                    </div>
                </ModalPopup>

            </div>
        )
    }
}

export default withTranslation('translations')(ContactForm);

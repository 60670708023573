import React, { useState, useEffect } from 'react';
import '../../App.css';
import './css/Espaços.css';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import Cooperativa from "../../assets/images/espaços/svg-map/index1";
import { SVGMap } from "react-svg-map";
import VrModalPopup from '../modalPopup/VrModalPopup';
import Tooltip from '@mui/material/Tooltip';

let spaceData;
let finalData;

const Accordion = (props) => {
    const [vrPopup, setVrPopup] = useState(false);
    const { t } = useTranslation("translations");
    const [vrData, setVrData] = useState(null);

    return (
        <>
            {props.coworkData.map((item, index) => {
                return (
                    <div key={item.location_id} className="accordion-item" style={{animationDelay: `${0.3 * index}s`}}>
                        <div className="accordion-title" onClick={() => props.toggle(item.location_id)}>
                            <div className="div-title">
                                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '50px' }}>
                                    {<p>COOPERATIVA {item.title.toUpperCase()}</p>}
                                </div>
                                <div>
                                    {item.location.toUpperCase()}
                                </div>
                                <div>
                                    {!item.online ? <p style={{ color: '#C8B852' }}>ABRE BREVEMENTE</p> : ''}
                                </div>
                            </div>
                            <div className="symbol">{props.clicked === item.location_id ? '-' : '+'}</div>
                        </div>
                        <div className={props.clicked === item.location_id ? 'show-div' : 'hide-div'}>
                            <div className="accordion-content">
                                <div className="content-container">
                                    <div className="content-left">
                                        {!item.online ? <p className="content-p-1">COWORK@ALDEIAS DE MONTANHA</p> : <p className="content-p">COWORK@ALDEIAS DE MONTANHA</p>}
                                        <img alt="" className="content-image" src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${item.portrait}`} />
                                    </div>
                                    <div className="content-right">
                                        <p className="content-description">{item.shortDescription}</p>
                                        {!item.online ? '' : (

                                            <div className="content-buttons">
                                                <Tooltip title={t('tooltips.reservar')} placement='top'>
                                                    <Link className="button1" to={{
                                                        pathname: "/espacos/espacosdetalhes",
                                                        state: {
                                                            item: item,
                                                            scroll: { top: 1450, left: 0 }
                                                        }
                                                    }}></Link>
                                                </Tooltip>
                                                <Tooltip title={t('tooltips.ver360')} placement='top'>
                                                    <div className="button2"
                                                        onClick={() => {
                                                            setVrData(item);
                                                            setVrPopup(true);
                                                        }}
                                                        style={{ cursor: 'pointer' }}></div>
                                                </Tooltip>
                                                <Tooltip title={t('tooltips.info')} placement='top'>
                                                    <Link className="button3" to={{
                                                        pathname: "/espacos/espacosdetalhes",
                                                        state: {
                                                            item: item,
                                                            scroll: { top: 0, left: 0 }
                                                        }
                                                    }}></Link>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <VrModalPopup data={vrData} trigger={vrPopup} close={() => {
                setVrPopup(false);
                setVrData(null)
            }} />
        </>
    )
}

function Espaços() {

    const [t] = useTranslation("translations");
    const [clicked, setClicked] = useState(false);
    const [coworkData, setCoworkData] = useState([])

    const activeCoworks = []
    const activePics = []

    const getData = () => {
        spaceData = t('espaçosDetalhes.espaços', { returnObjects: true });
        finalData = spaceData[Object.keys(spaceData)[0]].coworks
        setCoworkData([...finalData])
    }

    useEffect(() => {
        if (coworkData.length < 1) getData()
    }, [coworkData])

    const getLocationPin = (locationID) => {
        switch (locationID) {
            case "0": return "190";
            case "1": return "187";
            case "2": return "191";
            case "3": return "185";
            case "4": return "189";
            case "5": return "186";
            case "6": return "192";
            case "7": return "193";
            case "8": return "188";
            default: return '0'
        }
    }

    coworkData.forEach(cowork => {
        activeCoworks.push(cowork.location_id.toString())
        activePics.push(getLocationPin(cowork.location_id.toString()))
    })

    const scrollWindow = (top, left) => {
        window.scrollBy({
            top: top,
            left: left,
            behavior: 'smooth'
        })
    }

    const toggle = (id) => {

        if (activeCoworks.includes(id.toString())) {

            if (clicked === id) {

                window.innerWidth > 960 ? scrollWindow(-350, 0) : scrollWindow(-550, 0);

                return setClicked(null);
            }

            window.innerWidth > 960 ? scrollWindow(350, 0) : scrollWindow(550, 0);

            setClicked(id);
        }
    }

    const getLocationClassName = (location, index) => {


        if (activePics.includes(location.id)) {
            return `svg-map__location--pin${location.id}`
        }

        if (location.id > 184) {
            return `svg-map__location--pin--hidden`
        }

        if (location.id > 8 && location.id < 185) {
            return `svg-map__location--names${location.id}`
        }

        if (activeCoworks.includes(location.id)) {
            return `svg-map__location--id${location.id}`
        }

        return `svg-map__location--null`
    }

    return (
        <div className="background-container">

            <div className="container">
                <div className="right-container" >
                    <SVGMap
                        map={Cooperativa}
                        locationClassName={getLocationClassName}
                        onLocationClick={(data) => toggle(parseInt(data.target.id))}

                    />
                </div>

                <div className="left-container">
                    <div className="text-container">
                        <div className="title-espaços">
                            {t('espaços.title')}
                        </div>
                        <div className="description-espaços">
                            {t('espaços.text')}
                        </div>
                    </div>
                    <div className="mobile-map">
                        <SVGMap
                            map={Cooperativa}
                            locationClassName={getLocationClassName}
                            onLocationClick={(data) => toggle(parseInt(data.target.id))}

                        />
                    </div>
                    <div className="cards-espaços">
                        <Accordion toggle={toggle} clicked={clicked} coworkData={coworkData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Espaços;
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import './css/Feedback.css'
import '../../App.css';
import { useTranslation } from 'react-i18next'
import FeedbackForm from '../Form/FeedbackForm';
import axios from 'axios'

import ptImage from '../../assets/images/logos/pt.svg'
import ukImage from '../../assets/images/logos/uk.svg'

function Feedback() {
    const { t } = useTranslation("translations");
    const [redirect, setRedirect] = useState(false);
    const [alreadyFilled, setAlreadyFilled] = useState(false);
    const [reservationInfo, setReservationInfo] = useState();
    const [ready, setReady] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.get('id')) {
            setRedirect(true);
            return;
        }

        const getData = async () => {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/reservations/${urlParams.get('id')}`, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
                .then(res => {
                    if (res.data.feedbackGiven) setAlreadyFilled(true);
                    setReservationInfo(res.data);
                    setReady(true);
                }, err => {
                    setRedirect(true)
                })
        }

        getData();
    }, [])

    return (
        <>
            <div className="feedback-background-container">
                <div className="feedback-block-container">
                    {alreadyFilled ? (
                        <>
                            <div className="feedback-title">{t('feedbackGiven.title')}</div>
                            <div className="feedback-text">{t('feedbackGiven.subtitle')}</div>
                        </>
                    ) : (
                        <>
                            <div className="feedback-title">{t('feedback.title')}</div>
                            <div className="feedback-text">{t('feedback.subtitle')}</div>
                        </>
                    )}
                    {alreadyFilled ? (
                        ''
                    ) : (
                        ready && <FeedbackForm data={reservationInfo} redirect={() => setRedirect(true)} />
                    )}
                </div>
                <LanguageSelector />
            </div>
            {redirect && (<Redirect to='/' />)}
        </>
    )
}

const LanguageSelector = () => {
    const [t, i18n] = useTranslation('translations');
    const [ptFocused, setptFocused] = useState(false);
    const [enFocused, setenFocused] = useState(false);

    const defautlLanguage = window.navigator.userLanguage || window.navigator.language;

    useEffect(() => {
        handleClick(defautlLanguage);
    },[])

    const handleClick = language => {
        i18n.changeLanguage(language);

        if (language === 'pt') {
            setptFocused(true);
            setenFocused(false);
            return;
        }
        setenFocused(true);
        setptFocused(false);
    }

    return (
        <div className='feedback-languageSelector'>
            <div className={ptFocused ? 'flagDiv-selected' : 'flagDiv'} onClick={() => handleClick('pt')}>
                <img alt='PT' src={ptImage} width='50px' />
                <p style={{ marginLeft: '10px' }}>PT</p>
            </div>
            <div className={enFocused ? 'flagDiv-selected' : 'flagDiv'} onClick={() => handleClick('en')}>
                <img alt='EN' src={ukImage} width='50px' />
                <p style={{ marginLeft: '10px' }}>EN</p>
            </div>
        </div>
    )
}

export default Feedback;
import React, { Component } from 'react'
import Calendar from '../calendar/Calendar'
import MainContainer from '../ReservationsContainers/MainContainer'
// import { CoworksPT } from '../data/Spaces-Data'
import { withTranslation } from 'react-i18next'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Bars } from 'react-loading-icons';
import ModalPopup from '../modalPopup/ModalPopup';
// import ReactTooltip from 'react-tooltip'
import './css/ItemDetailsSlider.css'
import axios from 'axios'
import { init } from 'emailjs-com'
import VrModalPopup from '../modalPopup/VrModalPopup';
import TnCModalPopup from '../modalPopup/TnCModalPopup';
import HourPickerPopup from '../modalPopup/HourPickerPopup';
import Tooltip from '@mui/material/Tooltip';
import InfoContainer from '../ReservationsContainers/InfoContainer'

import moment from 'moment';

import '../pages/css/Espaçosdetalhes.css';
import { Redirect } from 'react-router-dom';

class BookForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cowork: props.data.title,
            quantidade: '1',
            nome: '',
            email: '',
            phone: '',
            location: '',
            dataInicio: '',
            dataFim: '',
            formErrors: { nome: '', email: '', phone: '', location: '', date: '' },
            nomeIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            locationIsValid: true,
            dataInicioIsValid: false,
            dataFimIsValid: false,
            conditionsIsChecked: false,
            formIsValid: false,
            submiting: false,
            triggerPopup: false,
            triggerTnCModal: false,
            hourPickerTrigger: false,
            error: false
        }
    }

    setInitialState = () => {
        return {
            nome: '',
            email: '',
            phone: '',
            location: '',
            formErrors: { nome: '', email: '', phone: '', location: '', date: '' },
            nomeIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            locationIsValid: true,
            conditionsIsChecked: false,
            formIsValid: false,
            triggerPopup: false,
            triggerTnCModal: false,
            submiting: false
        }
    }

    resetCalendar = () => {
        this.setState({
            dataInicio: null,
            dataFim: null,
            dataInicioIsValid: false,
            dataFimIsValid: false
        }, this.validateForm)
    }

    getDates = (from, to) => {
        this.setState({
            dataInicio: from,
            dataFim: to,
            dataInicioIsValid: true,
            dataFimIsValid: true,
            hourPickerTrigger: true
        }, this.validateForm)
    }

    submitHours = (hourIn, hourOut) => {
        this.setState({
            dataInicio: hourIn,
            dataFim: hourOut,
            dataInicioIsValid: true,
            dataFimIsValid: true,
            hourPickerTrigger: false
        }, this.validateForm)
        // console.log(hourIn, hourOut)
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ [name]: value }, () => { this.validateField(name, value) });
    }


    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nomeIsValid = this.state.nomeIsValid;
        let emailIsValid = this.state.emailIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let locationIsValid = this.state.locationIsValid;
        let conditionsIsChecked = this.state.conditionsIsChecked;

        switch (fieldName) {
            case 'nome':
                nomeIsValid = value.match(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/) && value.length > 4;
                fieldValidationErrors.nome = nomeIsValid ? '' : 'Invalid Name';
                break;
            case 'email':
                emailIsValid = value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                fieldValidationErrors.email = emailIsValid ? '' : 'Invalid Email';
                break;
            case 'phone':
                phoneIsValid = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' : 'Invalid Phone number'
                break;
            case 'location':
                locationIsValid = (value.match(/^[a-zA-Z\u00C0-\u00FF\s*]*$/) && value.length > 2) || value === '';
                fieldValidationErrors.location = locationIsValid ? '' : 'Provide valid location or none';
                break;
            case 'conditions':
                conditionsIsChecked = !this.state.conditionsIsChecked;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nomeIsValid: nomeIsValid,
            emailIsValid: emailIsValid,
            phoneIsValid: phoneIsValid,
            locationIsValid: locationIsValid,
            conditionsIsChecked: conditionsIsChecked
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nomeIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.locationIsValid &&
                this.state.conditionsIsChecked &&
                this.state.dataInicioIsValid &&
                this.state.dataFimIsValid
        });
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            cowork: event.target[0].value,
            quantidade: event.target[1].value,
            nome: event.target[2].value,
            location: event.target[3].value,
            phone: event.target[4].value,
            email: event.target[5].value,
            submiting: true
        });
        console.log(this.state.dataInicio)
        console.log(this.state.dataFim)

        const params = {
            image: this.props.data.portrait,
            cowork: this.state.cowork,
            quantidade: this.state.quantidade,
            clientName: this.state.nome,
            clientLocation: this.state.location,
            clientPhone: this.state.phone,
            clientEmail: this.state.email,
            conditions: this.state.conditionsIsChecked,
            checkin: this.state.dataInicio,
            checkout: this.state.dataFim
        }
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/sendReserEmail/`, params, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
            .then((res) => {
                this.calendar.handleResetClick();
                this.resetCalendar();
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true })
            }, (err) => {
                console.log(err.response);
                this.calendar.handleResetClick();
                this.resetCalendar();
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true, error: true })
            });
    }

    closePopup = () => {
        this.setState({ triggerPopup: false })
    }

    render() {
        const { t } = this.props;
        init(process.env.REACT_APP_USER_ID);

        return (
            <div className="booking-container">
                <div className="booking-title">
                    <p>{t('espaçosDetalhes.form.title')}</p>
                </div>
                <div className="booking-bottom">
                    <div className="booking-dropdown">
                        <div className="form-wrapper">
                            <form className="book-form" onSubmit={this.handleSubmit}>
                                <div className="form-input-fields">
                                    <label className="cowork-label">
                                        <div className="label-title">COWORK</div>
                                        <select className="input" name="cowork" onChange={(event) => this.handleInput(event)} value={this.state.cowork}>
                                            {/* {CoworksPT.coworks.map((cowork) => (
                                                <option key={cowork.location_id} value={cowork.title}>{cowork.title}</option>
                                            ))} */}
                                            <option value={this.state.cowork}>{this.state.cowork}</option>
                                        </select>
                                    </label>

                                    <label className="quantidade-label">
                                        <div className="label-title">{t('espaçosDetalhes.form.quantidade')}</div>
                                        <select className="input" name="quantidade" onChange={(event) => this.handleInput(event)} value={this.state.quantidade || '1 Pessoa'}>
                                            <option value={1}>1 {t('espaçosDetalhes.form.pessoa')}</option>
                                            <option value={2}>2 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={3}>3 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={4}>4 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={5}>5 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={6}>6 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={7}>7 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={8}>8 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={9}>9 {t('espaçosDetalhes.form.pessoas')}</option>
                                            <option value={10}>10 {t('espaçosDetalhes.form.pessoas')}</option>
                                        </select>
                                    </label>

                                    <label className="nome-label">
                                        <div className="label-title">{t('espaçosDetalhes.form.nome')} <span style={{ fontSize: "11px" }}>{t('espaçosDetalhes.form.sub-name')}</span></div>
                                        <input className={`input${this.errorClass(this.state.formErrors.nome)}`} name="nome" placeholder="-"
                                            value={this.state.nome}
                                            onChange={(event) => this.handleInput(event)} />
                                        <p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["nome"]}</p>
                                    </label>

                                    <label className="location-label" >
                                        <div className="label-title">{t('espaçosDetalhes.form.localizaçao')}</div>
                                        <input className={`input${this.errorClass(this.state.formErrors.location)}`} name="location" placeholder="-"
                                            value={this.state.location}
                                            onChange={(event) => this.handleInput(event)} />
                                        <p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["location"]}</p>
                                    </label>

                                    <label className="phone-label" >
                                        <div className="label-title">{t('espaçosDetalhes.form.telemovel')}</div>
                                        <input className={`input${this.errorClass(this.state.formErrors.phone)}`} name="phone" placeholder="-"
                                            value={this.state.phone}
                                            onChange={(event) => this.handleInput(event)} />
                                        <p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["phone"]}</p>
                                    </label>

                                    <label className="email-label" htmlFor="email">
                                        <div className="label-title">EMAIL</div>
                                        <input className={`input${this.errorClass(this.state.formErrors.email)}`} name="email" placeholder="-"
                                            value={this.state.email}
                                            onChange={(event) => this.handleInput(event)} />
                                        <p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["email"]}</p>
                                    </label>
                                    <div className="checkbox-div">
                                        <input type="checkbox" name="conditions" id="terms-checkbox"
                                            onChange={(event) => this.handleInput(event)}
                                        />
                                        <label style={{ marginLeft: "10px" }} htmlFor="terms-checkbox">{t('espaçosDetalhes.termos')}
                                            <span onClick={() => this.setState({ triggerTnCModal: true })}>
                                                <a style={{ cursor: 'pointer', textDecoration: 'underline' }}>{t('espaçosDetalhes.termos_link')}</a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="submitButton-Loading-div-espaços">
                                    <button className="book-submit-button" type="submit" disabled={!this.state.formIsValid || this.state.submiting}>{t('espaçosDetalhes.form.botão')}</button>
                                    {this.state.submiting ? <Bars className="loading-symbol" /> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="booking-calendar">
                        <div className='top-info'>
                            <p>{t('espaçosDetalhes.calendar.texto')}</p>
                            <div className="de-ate-text">
                                <div className="de-div">
                                    <div className="labels">{t('espaçosDetalhes.calendar.de')}</div>
                                    <div className="date-values">{this.state.dataInicio && moment(this.state.dataInicio).format('DD MMMM YYYY, HH:mm')}</div>
                                </div>
                                <div className="ate-div">
                                    <div className="labels">{t('espaçosDetalhes.calendar.ate')}</div>
                                    <div className="date-values">{this.state.dataFim && moment(this.state.dataFim).format('DD MMMM YYYY, HH:mm')} </div>
                                </div>
                            </div>
                        </div>
                        <Calendar onRef={ref => (this.calendar = ref)} getDates={this.getDates} reset={this.resetCalendar} />
                    </div>
                    <div className="booking-plant" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${this.props.data.blueprint})` }}></div>

                    <ModalPopup trigger={this.state.triggerPopup} width="400px" height="400px" title={t('common.sucesso')}>
                        <div className="popup-content">
                            <div className="popup-content-text">
                                {!this.state.error ? t('espaçosDetalhes.popup.popupMessage') : t('espaçosDetalhes.popup.popupMessageError')}
                            </div>
                        </div>
                        <div className="popup-buttons">
                            <button className="submit-btn" onClick={this.closePopup}>OK</button>
                        </div>
                    </ModalPopup>

                    <HourPickerPopup
                        trigger={this.state.hourPickerTrigger}
                        checkIn={this.state.dataInicio}
                        checkOut={this.state.dataFim}
                        submit={(checkin, checkout) => this.submitHours(checkin, checkout)}
                        close={() => this.setState({ hourPickerTrigger: false })}
                    />

                    <TnCModalPopup
                        trigger={this.state.triggerTnCModal} data={this.props.data} close={() => this.setState({ triggerTnCModal: false })} />
                </div>
            </div>
        )
    }
}

const Form = withTranslation("translations")(BookForm);

class Espaçosdetalhes extends React.Component {
    constructor(props) {
        super(props);

        if (props.location.state === undefined) {

            return;
        }

        this.state = {
            item: props.location.state.item,
            scroll: props.location.state.scroll,
            vrPopup: false
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            window.scroll(this.state.scroll);
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    render() {
        const AutoplaySlider = withAutoplay(AwesomeSlider);
        const { t } = this.props;

        if (this.props.location.state === undefined) {
            return (
                <Redirect to="/espaços" />
            );
        }

        return (
            <div className="main-container">
                <div className="upper-container">
                    <div className="slider-container">
                        <AutoplaySlider
                            fillParent={window.screen.width > 960 ? true : false}
                            bullets={true}
                            organicArrows={false}
                            play={false}
                            interval={3000}
                            cancelOnInteraction={false}
                        >
                            <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${this.state.item.sliderImage1}`} />
                            <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${this.state.item.sliderImage2}`} />
                            <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${this.state.item.sliderImage3}`} />
                        </AutoplaySlider>
                    </div>
                    <div className="info-container">
                        <div className="info-title">
                            <div>{this.state.item.title.toUpperCase()}</div>
                            <div>COOPERATIVA COWORK</div>
                        </div>
                        <div className="info-description">{this.state.item.fullDescription}</div>
                        <div className="info-buttons">

                            <Tooltip title={t('tooltips.reservar')} placement='top'>
                                <div className="book-button"
                                    onClick={() => window.scroll({ top: 700, left: 0, behavior: "smooth" })}
                                    style={{ cursor: 'pointer' }}
                                ></div>
                            </Tooltip>

                            <Tooltip title={t('tooltips.ver360')} placement='top'>
                                <div className="vr-visit-button"
                                    onClick={() => this.setState({ vrPopup: true })}
                                    style={{ cursor: 'pointer' }}
                                ></div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <InfoContainer cowork={this.state.item} />
                <div className="booking-title-div">
                    <p className='booking-title'>{t('espaçosDetalhes.form.title')}</p>
                    <p className='booking-subtitle'>{this.state.item.title}</p>
                </div>
                <MainContainer cowork={this.state.item} />
                {/* <Form data={this.state.item} /> */}
                <VrModalPopup data={this.state.item} trigger={this.state.vrPopup} close={() => this.setState({ vrPopup: false })} />
            </div>
        );
    }
}

export default withTranslation('translations')(Espaçosdetalhes);
import React, { useState, useEffect, useRef } from 'react'
import DateContainer from './DateContainer'
import FormContainer from './FormContainer'
import SeatsContainer from './SeatsContainer'
import TopInfoBar from './TopInfoBar'
import { Divider, Tooltip } from '@mui/material'
import ConfirmationDialog from './ConfirmationDialog'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Delete } from '@mui/icons-material'
import 'moment/locale/pt'
import 'moment/locale/en-gb'

import './css/MainContainer.css'
import ReviewContainer from './ReviewContainer'

function MainContainer(props) {
    const { t, i18n } = useTranslation('translations')
    const [state, setState] = useState({ dates: [{ people: 1, filled: false }], datesComplete: false, formIsValid: false })
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [confirmationDialogState, setConfirmationDialogState] = useState(0)
    const [confirmDialogData, setConfirmDialogData] = useState()
    const formDivRef = useRef(null)

    moment.locale(i18n.language)

    const updateParentState = (property, value, index) => {
        if (!isNaN(index)) {
            state.dates[index][property] = value
        } else {
            setState(state => ({
                ...state,
                ...{ [property]: value }
            }))
        }
    }

    const addNewReservation = () => {
        const dates = state.dates
        setState(state => ({ ...state, dates: [...dates, { people: 1, filled: false }] }))
    }

    const removeReservation = index => {
        const data = state.dates
        data.splice(index, 1)
        if (data.some(date => date.filled)) {
            setState(state => ({ ...state, datesComplete: true, dates: data }))
        } else {
            setState(state => ({ ...state, datesComplete: false, dates: data }))
        }
    }

    useEffect(() => {
        if (state.datesComplete) window.scrollBy({ top: 650, behavior: 'smooth' })
    }, [state.datesComplete])

    useEffect(() => {
        if (state.dates.length === 1 && state.dates[0]?.filled) {
            window.scrollBy({ top: 650, behavior: 'smooth' })
        }
    }, [state.dates])

    const submitInfo = async () => {
        const dates = state.dates.filter(date => date.filled)
        setConfirmationDialogState(1)
        setShowConfirmationDialog(true)

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reservations`,
            {
                dates,
                cowork: props.cowork.title,
                client_name: state.nome,
                client_email: state.email,
                client_nif: state.nif,
                message: state.message ? state.message : '',
                client_phone: state.phone,
                client_address: state.location,
                share_data: state.share_data
            }, {
            headers: {
                'apikey': process.env.REACT_APP_API_KEY_FO
            }
        }).then(res => {
            console.log(res.data)
            setConfirmationDialogState(2)
            setConfirmDialogData(`${t('reservation.confirmation.body.body2')} ${props.cowork.title}.`)
        }, err => {
            console.log(err.response)
            setConfirmationDialogState(3)
            setConfirmDialogData(`${t('reservation.confirmation.body.body3')} ${err.response.data}`)
        })
    }

    const handleConfirmationDialogClose = () => {
        window.location.reload(false)
    }

    return (
        <div className='main-container-div'>
            <TopInfoBar formRef={formDivRef} state={state} cowork={props.cowork} />
            {state.dates.map((date, index) => {
                return (
                    <div className='date-container-wrapper' key={index}>
                        <div className='date-container-top'>
                            {index > 0 &&
                                <Tooltip title='Eliminar Datas'>
                                    <div className='date-container-delete' onClick={() => removeReservation(index)}>
                                        <Delete />
                                        <p>Eliminar data</p>
                                    </div>
                                </Tooltip>
                            }
                        </div>
                        <DateContainer updateParentState={updateParentState} cowork={props.cowork} index={index} />
                    </div>
                )
            })}
            {state.dates.length < 5 &&
                <div className='add-dates-div' onClick={() => addNewReservation()}>
                    <p className='main-container-title'>{t('reservation.dateContainer.addMore')}</p>
                </div>
            }
            <div className={`form-review-container ${state.datesComplete ? '' : 'hide'}`} ref={formDivRef}>
                {state.datesComplete &&
                    <>
                        <FormContainer updateParentState={updateParentState} cowork={props.cowork} state={state} />
                        <ReviewContainer state={state} cowork={props.cowork} submit={submitInfo} updateParentState={updateParentState} />
                    </>
                }
            </div>
            <ConfirmationDialog
                open={showConfirmationDialog}
                close={() => handleConfirmationDialogClose()}
                state={confirmationDialogState}
                data={confirmDialogData}
            />
        </div>
    )
}

export default MainContainer
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './css/HomeSlider.css'
import './css/Home.css'

import HomePicture1 from '../../assets/images/home/HomePicture1.jpg';
import HomePicture2 from '../../assets/images/home/HomePicture2.jpg';
import HomePicture3 from '../../assets/images/home/HomePicture3.jpg';

import HomePictureMobile1 from '../../assets/images/home/HomePictureMobile1.jpg';
import HomePictureMobile2 from '../../assets/images/home/HomePictureMobile2.jpg';
import HomePictureMobile3 from '../../assets/images/home/HomePictureMobile3.jpg';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home() {

    const getPictures = () => {
        if (window.innerWidth > 960) {
            return [HomePicture1, HomePicture2, HomePicture3]
        }

        return [HomePictureMobile1, HomePictureMobile2, HomePictureMobile3]
    }

    return (
        <div className="outter-container">
            <div className="home-slider-container">
                <AutoplaySlider
                    fillParent={true}
                    bullets={false}
                    organicArrows={true}
                    play={true}
                    interval={5000}
                    cancelOnInteraction={false}
                >
                    {getPictures().map((picture, index) => {
                        return (
                            <div key={index} data-src={picture} />
                        )
                    })}
                </AutoplaySlider>
            </div>
        </div>
    )
}

export default Home;
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Calendar from '../calendar/Calendar'
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Rating, Divider, Tooltip, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment'
import axios from 'axios'
import { RRule } from 'rrule'
import { CheckInTopArrow, CheckInDownArrow, CheckOutDownArrow, CheckOutTopArrow } from './HoursArrows';
import ContactDialog from './ContactDialog';

import './css/DateContainer.css'
import CustomRating, { CompactRating } from '../Rating/CustomRating';

const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: '#61634f'
	},
	'& .MuiRating-iconHover': {
		color: '#61634f'
	}
})

function DateContainer({ updateParentState, cowork, index }) {
	const { t } = useTranslation('translations')
	const [people, setPeople] = useState(1)
	const [reservations, setReservations] = useState([])
	const [checkInDate, setCheckInDate] = useState(null)
	const [checkOutDate, setCheckOutDate] = useState(null)
	const [selectedMonth, setSelectedMonth] = useState()
	const [availableCombination, setAvailableCombination] = useState({ available: true, unavailableDay: '' })
	const [showCheckInArrows, setShowCheckInArrows] = useState(false)
	const [showCheckOutArrows, setShowCheckOutArrows] = useState(false)
	const [showUnavailablePopup, setShowUnavailablePopup] = useState(false)
	const [contactDialogOpen, setContactDialogOpen] = useState(false)

	useEffect(() => {
		const fetch = async () => {
			await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/checkreservations`,
				{
					date: selectedMonth ? selectedMonth : moment(),
					cowork: cowork.title,
				}, {
				headers: {
					'apikey': process.env.REACT_APP_API_KEY_FO
				}
			}).then(res => {
				setReservations(res.data)
			}, err => {
				console.log(err)
			})
		}

		fetch()
	}, [selectedMonth, cowork])

	const resetCalendar = () => {
		setCheckInDate(null)
		setCheckOutDate(null)
		setAvailableCombination({ available: true, unavailableDay: '' })
	}

	const getDates = (from, to) => {
		setCheckInDate(from)
		setCheckOutDate(to)
	}

	const handlePeopleSelection = (value) => {
		if (!value) return;

		setPeople(value)
		updateParentState('people', value, index)
	}

	useEffect(() => {
		if (checkInDate) {
			updateParentState('checkIn', checkInDate, index)
			updateParentState('datesComplete', checkIfDatesPicked())
			setShowCheckInArrows(true)
		} else {
			setShowCheckInArrows(false)
		}
	}, [checkInDate])

	useEffect(() => {
		if (checkOutDate) {
			const datesValid = checkIfDatesPicked()
			updateParentState('filled', datesValid, index)
			updateParentState('checkOut', checkOutDate, index)
			updateParentState('datesComplete', datesValid)
			setShowCheckOutArrows(true)
		} else {
			setShowCheckOutArrows(false)
		}
	}, [checkOutDate])

	const checkIfDatesPicked = () => {
		const hoursPicked = moment(checkInDate).utc().minutes() !== 30 && moment(checkOutDate).utc().minutes() !== 30
		const datesPicked = checkInDate !== null && checkOutDate !== null

		return hoursPicked && datesPicked
	}

	const handleUnavailableDaysPopup = (show) => {
		let timeout;
		setShowUnavailablePopup(show)

		if (timeout) clearTimeout(timeout)

		if (show) {
			timeout = setTimeout(() => {
				setShowUnavailablePopup(false)
			}, 3000)
		}
	}

	return (
		<div className='date-main-container'>
			{showUnavailablePopup &&
				<ClickAwayListener onClickAway={() => setShowUnavailablePopup(false)}>
					<div onClick={() => setShowUnavailablePopup(false)} className={`unavailable-days-popup ${showUnavailablePopup ? 'show' : 'hide'}`}>
						<p>{t('reservation.dateContainer.notAvailableMessage')}</p>
					</div>
				</ClickAwayListener>
			}
			<p className='main-container-title'>{t('reservation.dateContainer.title')}</p>
			<div className='people-selection-div'>
				<p style={{ color: 'var(--text-dark)' }}>{t('reservation.dateContainer.people.title')}</p>
				<div className='people-selection'>
					{window.innerWidth > 960 ? (
						<>
							<CustomRating
								icon={<PersonIcon fontSize='large' />}
								emptyIcon={<PersonOutlineIcon fontSize='large' />}
								max={cowork.capacity}
								value={people}
								onChange={(value) => handlePeopleSelection(value)}
							/>
							<p style={{ color: 'var(--text-dark)', fontSize: '14px' }}>{people > 1 ? `${people} ${t('reservation.dateContainer.people.people')}` : `${people} ${t('reservation.dateContainer.people.person')}`}</p>
						</>
					) : (
						<select className="mobile-person-input" onChange={(e) => handlePeopleSelection(parseInt(e.target.value))} value={people}>
							{Array.from({ length: cowork.capacity }).map((elem, index) => {
								return (
									<option key={index} value={index + 1}>{index > 0 ? `${index + 1} ${t('reservation.dateContainer.people.plural')}` : `${index + 1} ${t('reservation.dateContainer.people.single')}`}</option>
								)
							})}
						</select>
					)}
				</div>
			</div>
			<p className='big-group-text'>{t('reservation.contact.link1')}<span className='big-group-span' onClick={() => setContactDialogOpen(true)}>{t('reservation.contact.span')}</span>{t('reservation.contact.link2')}</p>
			<div className='main-container-inner'>
				<div className={`right-side-div${checkInDate && checkOutDate ? ' show' : ' hide'}`}>
					<p className='left-side-subtitle'>{t('reservation.dateContainer.hours.title')}</p>
					<div className="dates-div">
						<p className='dates-bottom-info'>{t('reservation.dateContainer.hours.disclaimer')}</p>
						{!availableCombination.available &&
							<div className='unavailable-combination-warning'>
								<p>{t('reservation.dateContainer.hours.unavailable.first')}</p>
								<p>{`${moment(availableCombination.unavailableDay).format('DD MMMM')} ${t('reservation.dateContainer.hours.unavailable.second')}`}</p>
							</div>
						}
						<div className="date-inner-div">
							<div style={{ height: '20%', width: '100%' }}>
								<div className="labels">{t('espaçosDetalhes.calendar.de')}</div>
								{checkInDate ?
									<>
										{moment(checkInDate).utc().minutes() !== 0 ? (
											<p style={{ marginLeft: '10px' }}>{moment(checkInDate).utc(false).format('dddd, YYYY MMMM DD,  --:--')}</p>
										) : (
											<p style={{ marginLeft: '10px' }}>{moment(checkInDate).utc(false).format('dddd, YYYY MMMM DD, HH:mm')}</p>
										)}
										<p style={{ paddingTop: '15px', textAlign: 'center' }}>{t('reservation.dateContainer.hours.checkInSubtitle')}</p>
									</>
									:
									<p>{t('reservation.dateContainer.hours.subtitle')}</p>
								}
							</div>
							<CheckInTopArrow disabled={!showCheckInArrows} />
							<div className='date-div-hours'>
								{checkInDate &&
									<CheckInHourPicker
										people={people}
										cowork={cowork}
										checkInDate={checkInDate}
										setCheckInDate={setCheckInDate}
										checkOutDate={checkOutDate}
										availableCombination={availableCombination}
										t={t}
										data={reservations.filter(day => {
											if (moment(checkInDate).hour() === 0) { //Não é a coisa mais bonita de sempre, mas nem tudo pode ser bonito neste mundo
												const splitted = checkInDate.split('T')
												return moment(day.day).isSame(`${splitted[0]}T12:00:00.000Z`, 'day', '[]')
											}

											return moment(day.day).isSame(checkInDate, 'day', '[]')
										})}
									/>
								}
							</div>
							<CheckInDownArrow disabled={!showCheckInArrows} />
						</div>
						<Divider orientation='vertical' sx={{ height: '90%', margin: '10px' }} />
						<div className="date-inner-div">
							<div style={{ height: '20%', width: '100%' }}>
								<div className="labels">{t('espaçosDetalhes.calendar.ate')}</div>
								{checkOutDate ?
									<>
										{moment(checkOutDate).utc().minutes() !== 0 ? (
											<p style={{ marginLeft: '10px' }}>{moment(checkOutDate).utc(false).format('dddd, YYYY MMMM DD,  --:--')}</p>
										) : (
											<p style={{ marginLeft: '10px' }}>{moment(checkOutDate).utc(false).format('dddd, YYYY MMMM DD, HH:mm')}</p>
										)}
										<p style={{ paddingTop: '15px', textAlign: 'center' }}>{t('reservation.dateContainer.hours.checkOutSubtitle')}</p>
									</>
									:
									<p>{t('reservation.dateContainer.hours.subtitle')}</p>
								}
							</div>
							<CheckOutTopArrow disabled={!showCheckOutArrows} />
							<div className='date-div-hours'>
								{checkOutDate &&
									<CheckOutHourPicker
										people={people}
										cowork={cowork}
										checkOutDate={checkOutDate}
										checkInDate={checkInDate}
										t={t}
										setCheckOutDate={setCheckOutDate}
										setAvailableCombination={setAvailableCombination}
										availableCombination={availableCombination}
										data={reservations.filter(day => {
											if (moment(checkOutDate).hour() === 0) {
												const splitted = checkOutDate.split('T')
												return moment(day.day).isSame(`${splitted[0]}T12:00:00.000Z`, 'day', '[]')
											}

											return moment(day.day).isSame(checkOutDate, 'day', '[]')
										})}
										reservations={reservations}
									/>

								}
							</div>
							<CheckOutDownArrow disabled={!showCheckOutArrows} />
						</div>
					</div>
				</div>
				<Divider className='vertical-divider' orientation='vertical' sx={checkInDate && checkOutDate ? { height: '80%' } : { display: 'none' }} />
				<div className='left-side-div'>
					<p className='calendar-bottom-info'>{t('reservation.dateContainer.calendar.disclaimer')}</p>
					<div className='left-side-text'>
						<p className='left-side-subtitle'>{t('reservation.dateContainer.calendar.title')}</p>
					</div>
					<Calendar
						getDates={getDates}
						reset={resetCalendar}
						reservations={reservations}
						cowork={cowork}
						people={people}
						setSelectedMonth={setSelectedMonth}
						showUnavailablePopup={handleUnavailableDaysPopup}
					/>
				</div>
			</div>
			<ContactDialog open={contactDialogOpen} close={() => setContactDialogOpen(false)} />
		</div>
	)
}

export default DateContainer

const CheckInHourPicker = ({ data, people, cowork, setCheckInDate, checkInDate, checkOutDate, availableCombination, t }) => {
	if (!data[0]) return ''

	let hoursArray;

	if (!data[0].hours) {
		hoursArray = new RRule({
			freq: RRule.HOURLY,
			dtstart: moment(data[0].day).hour(0).minutes(0).utc(true).toDate(),
			until: moment(data[0].day).hours(23).minutes(0).utc(true).toDate(),
			interval: 1,
		}).all()
	}

	const checkAvailableSpot = (elem) => {
		if (elem.reservation.length === 0) return false
		if (checkHoursBetweenAvailable(elem)) return true

		const seatsOccupied = elem.reservation.reduce((prev, current) => {
			return {
				quantity: prev.quantity + current.quantity
			}
		})

		return seatsOccupied.quantity + people > cowork.capacity
	}

	const checkHoursBetweenAvailable = (elem) => {

		if (moment(checkInDate).isBefore(moment(checkOutDate), 'day')) {
			const hoursToCheck = [...data[0].hours].filter(hour => moment(hour.hour).isSameOrAfter(moment(elem.hour), 'hour'))

			return hoursToCheck.some(hour => {
				if (hour.reservation && hour.reservation.length > 0) {
					const seatsOccupied = hour.reservation.reduce((prev, current) => {
						return {
							quantity: prev.quantity + current.quantity
						}
					})
					return seatsOccupied.quantity + people > cowork.capacity
				}
				return false
			})
		}
		return false;
	}

	const handleHourClick = (elem, type) => {
		if (!availableCombination.available) return;
		if (type === 'withData') {
			if (checkAvailableSpot(elem)) return;
			setCheckInDate(elem.hour)
			return;
		}
		setCheckInDate(moment(elem).toISOString())
	}

	const getSeatsOccupiedNumber = (elem) => {
		if (elem.reservation.length > 0) {
			const seatsOccupied = elem.reservation.reduce((prev, current) => {
				return {
					quantity: prev.quantity + current.quantity
				}
			})
			return seatsOccupied.quantity
		}

		return 0
	}

	return (
		<div className='checkIn-pick-hours-container'>
			{data && data[0].hours ? (
				data[0].hours.map((elem, index) => {
					const occupiedSeats = getSeatsOccupiedNumber(elem)
					return (
						<Tooltip disableInteractive enterDelay={500} enterNextDelay={500} key={index} title={`${occupiedSeats} ${t('reservation.dateContainer.hours.tooltip')}`}>
							<div
								style={moment(checkInDate).isSame(elem.hour, 'hour', '[]') && moment(checkInDate).minute() === 0 ? { backgroundColor: 'var(--text-light)', borderRadius: '10px' } : {}}
								key={index}
								className={`hours-container-inner ${checkAvailableSpot(elem) ? 'disabled' : ''}`}
								onClick={() => handleHourClick(elem, 'withData')}
							>
								<button className='hours-btn' disabled={checkAvailableSpot(elem)} >
									{moment(elem.hour).utc(false).format('HH:mm')}
								</button>
								{cowork.capacity > 10 ? (
									<CompactRating t={t} value={occupiedSeats} max={cowork.capacity} />
								) : (
									<StyledRating
										name='people-selection'
										value={occupiedSeats}
										readOnly
										icon={<PersonIcon />}
										emptyIcon={<PersonOutlineIcon />}
										max={cowork.capacity}
									/>
								)}
							</div>
						</Tooltip>
					)
				})
			) : (
				hoursArray.map(hour => {
					return (
						<Tooltip disableInteractive enterDelay={500} enterNextDelay={500} key={hour} title={`${t('reservation.dateContainer.hours.tooltipEmpty')}`}>
							<div
								style={moment(checkInDate).isSame(hour, 'hour', '[]') && moment(checkInDate).minutes() === 0 ? { backgroundColor: 'var(--text-light)', borderRadius: '10px' } : {}}
								key={hour}
								className={`hours-container-inner`}
								onClick={() => handleHourClick(hour, 'withoutData')}
							>
								<button className='hours-btn' key={hour}>{moment(hour).utc(false).format('HH:mm')}</button>
								{cowork.capacity > 10 ? (
									<CompactRating t={t} value={0} max={cowork.capacity} />
								) : (
									<StyledRating
										name='people-selection'
										value={0}
										readOnly
										icon={<PersonIcon />}
										emptyIcon={<PersonOutlineIcon />}
										max={cowork.capacity}
									/>
								)}
							</div>
						</Tooltip>
					)
				})
			)}

		</div>
	)
}

const CheckOutHourPicker = ({ data, people, cowork, checkOutDate, checkInDate, setCheckOutDate, reservations, setAvailableCombination, availableCombination, t }) => {

	useEffect(() => {
		const availableCombination = checkIfSelectedDaysAvailable(reservations, checkInDate, checkOutDate, people, cowork.capacity);
		setAvailableCombination(availableCombination)
	}, [checkOutDate])

	useEffect(() => {
		setCheckOutDate(moment(checkOutDate).hour(12).minutes(30))
	}, [checkInDate])

	if (!data[0]) return ''

	let hoursArray;

	if (!data[0].hours) {
		hoursArray = new RRule({
			freq: RRule.HOURLY,
			dtstart: moment(data[0].day).hour(0).utc(true).minutes(0).toDate(),
			until: moment(data[0].day).hours(23).utc(true).minutes(0).toDate(),
			interval: 1,
		}).all()
	}

	const checkIfSpotUnavailable = (elem) => {
		if (moment(checkInDate).minutes() !== 0) return true

		if (checkHoursBetweenAvailable(elem)) return true;

		if (moment(elem.hour).isBefore(moment(checkInDate))) return true
		if (elem.reservation.length === 0) return false

		const seatsOccupied = elem.reservation.reduce((prev, current) => {
			return {
				quantity: prev.quantity + current.quantity
			}
		})

		return seatsOccupied.quantity + people > cowork.capacity
	}

	const checkHoursBetweenAvailable = (elem) => {

		const hoursToCheck = [...data[0].hours].filter(hour => moment(hour.hour).isBetween(moment(checkInDate), moment(elem.hour), 'hour', '(]'))

		return hoursToCheck.some(hour => {
			if (hour.reservation && hour.reservation.length > 0) {
				const seatsOccupied = hour.reservation.reduce((prev, current) => {
					return {
						quantity: prev.quantity + current.quantity
					}
				})
				return seatsOccupied.quantity + people > cowork.capacity
			}
			return false
		})

	}

	const handleHourClick = (elem, type) => {
		if (!availableCombination.available) return;

		if (type === 'withData') {
			if (checkIfSpotUnavailable(elem)) return;

			if (moment(elem.hour).utc(false).add(1, 'hour').hour() === 0) {
				const date = moment(elem.hour).minutes(59).toISOString()
				setCheckOutDate(date)
				return;
			}
			const date = moment(elem.hour).add(1, 'hour').toISOString()
			setCheckOutDate(date)
			return;
		}

		if (handleEmptyHoursAvailability(elem)) return;

		if (moment(elem).utc(false).add(1, 'hour').hour() === 0) {
			const date = moment(elem).minutes(59).toISOString()
			setCheckOutDate(date)
			return;
		}
		const date = moment(elem).add(1, 'hour').toISOString()
		setCheckOutDate(date)
		return;
	}

	const getSeatsOccupiedNumber = (elem) => {
		if (elem.reservation.length > 0) {
			const seatsOccupied = elem.reservation.reduce((prev, current) => {
				return {
					quantity: prev.quantity + current.quantity
				}
			})
			return seatsOccupied.quantity
		}

		return 0
	}

	const handleEmptyHoursAvailability = (hour) => {
		if (moment(checkInDate).minutes() !== 0) return true
		if (moment(hour).isSameOrBefore(moment(checkInDate))) return true
		return false
	}

	const checkIfHourSelected = (elem) => {
		const hour = elem.hour ? elem.hour : elem

		if (moment(hour).utc(false).add(1, 'hour').hour() === 0) {
			const date = moment(hour).minutes(59).toISOString()
			return moment(checkOutDate).isSame(date, 'minutes', '[]')
		}
		return moment(checkOutDate).subtract(1, 'hour').isSame(hour, 'hour', '[]') && moment(checkOutDate).minutes() === 0
	}

	return (
		<div className='checkOut-pick-hours-container'>
			{data && data[0].hours ? (
				data[0].hours.map((elem, index) => {
					const occupiedSeats = getSeatsOccupiedNumber(elem)
					return (
						<Tooltip disableInteractive enterDelay={500} enterNextDelay={500} key={index} title={`${occupiedSeats} ${t('reservation.dateContainer.hours.tooltip')}`}>
							<div
								style={checkIfHourSelected(elem) ? { backgroundColor: 'var(--text-light)', borderRadius: '10px' } : {}}
								key={index}
								className={`hours-container-inner ${checkIfSpotUnavailable(elem) ? 'disabled' : ''}`}
								onClick={() => handleHourClick(elem, 'withData')}
							>
								<button className='hours-btn' disabled={checkIfSpotUnavailable(elem)} >
									{moment(elem.hour).utc(false).add(1, 'hour').hour() === 0 ?
										moment(elem.hour).utc(false).minutes(59).format('HH:mm')
										:
										moment(elem.hour).utc(false).add(1, 'hour').format('HH:mm')
									}
								</button>
								{cowork.capacity > 10 ? (
									<CompactRating t={t} value={occupiedSeats} max={cowork.capacity} />
								) : (
									<StyledRating
										name='people-selection'
										value={occupiedSeats}
										readOnly
										icon={<PersonIcon />}
										emptyIcon={<PersonOutlineIcon />}
										max={cowork.capacity}
									/>
								)}
							</div>
						</Tooltip>
					)
				})
			) : (
				hoursArray.map(hour => {
					return (
						<Tooltip disableInteractive enterDelay={500} enterNextDelay={500} key={hour} title={`${t('reservation.dateContainer.hours.tooltipEmpty')}`}>
							<div
								style={checkIfHourSelected(hour) ? { backgroundColor: 'var(--text-light)', borderRadius: '10px' } : {}}
								key={hour}
								className={`hours-container-inner ${handleEmptyHoursAvailability(hour) ? 'disabled' : ''}`}
								onClick={() => handleHourClick(hour, 'withoutData')}
							>
								<button className='hours-btn' key={hour}>
									{moment(hour).utc(false).add(1, 'hour').hour() === 0 ?
										moment(hour).utc(false).minutes(59).format('HH:mm')
										:
										moment(hour).utc(false).add(1, 'hour').format('HH:mm')
									}
								</button>
								{cowork.capacity > 10 ? (
									<CompactRating t={t} value={0} max={cowork.capacity} />
								) : (
									<StyledRating
										name='people-selection'
										value={0}
										readOnly
										icon={<PersonIcon />}
										emptyIcon={<PersonOutlineIcon />}
										max={cowork.capacity}
									/>
								)}
							</div>
						</Tooltip>
					)
				})
			)}
		</div>
	)
}


const checkIfSelectedDaysAvailable = (reservations, checkIn, checkOut, people, coworkCapacity) => {

	if (moment(checkIn).isSame(moment(checkOut), 'day')) return { available: true, unavailableDay: '' }

	const betweenDates = [...reservations].filter(reservation => {
		return moment(reservation.day).isBetween(moment(checkIn), moment(checkOut), 'day', '[]')
	})
	if (betweenDates.length === 0) return { available: true, unavailableDay: '' }
	let unavailableDay;

	const firstDayAvailable = () => {
		if (betweenDates[0].hours && betweenDates[0].hours.length > 0) {
			if (betweenDates[0].hours[betweenDates[0].hours.length - 1].reservation.length > 0) {
				const checkInSeatsOccupied = betweenDates[0].hours[betweenDates[0].hours.length - 1].reservation.reduce((prev, current) => {
					return {
						quantity: prev.quantity + current.quantity
					}
				})
				unavailableDay = betweenDates[0].day
				return !(people + checkInSeatsOccupied.quantity > coworkCapacity)
			}
			return true
		}
		return true
	}

	const lastDayAvailable = () => {
		if (betweenDates[betweenDates.length - 1].hours && betweenDates[betweenDates.length - 1].hours.length > 0) {
			if (betweenDates[betweenDates.length - 1].hours[0].reservation.length > 0) {
				const checkInSeatsOccupied = betweenDates[betweenDates.length - 1].hours[0].reservation.reduce((prev, current) => {
					return {
						quantity: prev.quantity + current.quantity
					}
				})
				unavailableDay = betweenDates[betweenDates.length - 1].day
				return !(people + checkInSeatsOccupied.quantity > coworkCapacity)
			}
			return true
		}
		return true
	}

	if (betweenDates.length < 3) return { available: firstDayAvailable() && lastDayAvailable(), unavailableDay }

	const betweenDays = betweenDates.slice(1, betweenDates.length - 1);

	const daysBetweenAvailable = betweenDays.every(day => {
		if (day.hours && day.hours.length > 0) {
			return day.hours.every(hour => {
				if (hour.reservation.length > 0) {
					const seatsOccupied = hour.reservation.reduce((prev, current) => {
						return { quantity: prev.quantity + current.quantity }
					})
					unavailableDay = hour.hour
					return !(seatsOccupied.quantity + people > coworkCapacity)
				}
				return true
			})
		}
		return true
	})

	return { available: firstDayAvailable() && lastDayAvailable() && daysBetweenAvailable, unavailableDay }
}
import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Divider } from '@mui/material'
import bigX from '../../assets/icons/bigX.svg'
import bigCheck from '../../assets/icons/bigCheck.svg'
import { useTranslation } from 'react-i18next'
import './css/ConfirmationDialog.css'
import { SpinningCircles } from 'react-loading-icons'

function ConfirmationDialog(props) {
    const { t } = useTranslation('translations')
    const [state, setState] = useState(props.state)

    useEffect(() => {
        setState(props.state)
    }, [props.state])

    const handleCloseDialog = () => {
        if (props.state === 1) return;
        props.close()
    }

    const getRespectiveIcon = () => {
        switch (props.state) {
            case 1: return <LoadingIcon />
            case 2: return <img alt='icon' src={bigCheck} height='150px' width='150px' />
            case 3: return <img alt='icon' src={bigX} height='150px' width='150px' />
            default: return ''
        }
    }

    const getRespectiveTitle = () => {
        switch (props.state) {
            case 1: return <p>{t('reservation.confirmation.titles.title1')}</p>
            case 2: return <p>{t('reservation.confirmation.titles.title2')}</p>
            case 3: return <p>{t('reservation.confirmation.titles.title3')}</p>
            default: return ''
        }
    }

    return (
        <Dialog
            maxWidth='md'
            fullScreen={window.innerWidth < 960}
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="max-width-dialog-title"
            className='reservation-confirmation-Dialog'
        >
            <DialogContent className='reservation-dialog-content'>
                <div className='reservation-dialog-content-top'>
                    {getRespectiveTitle()}
                </div>
                <Divider orientation='horizontal' />
                <div className='reservation-dialog-content-middle'>
                    <div className='confirmation-dialog-loading-icon'>
                        {getRespectiveIcon()}
                    </div>
                    <div className='confirmation-dialog-loading-info'>
                        {state !== 1 ? (
                            <p className='confirmation-dialog-state-text'>{props.data}</p>
                        ) : (
                            <p className='confirmation-dialog-state-text'>{t('reservation.confirmation.body.body1')}</p> 
                        )}
                    </div>
                </div>
                <Divider orientation='horizontal' />
                <div className='reservation-dialog-content-bottom'>
                    <button className={`confirmation-dialog-btn ${state === 1 && 'hidden'}`} onClick={props.close}>OK</button>
                </div>

            </DialogContent >
        </Dialog >
    )
}

export default ConfirmationDialog

const LoadingIcon = () => {
    return (
        <div className='confirmation-dialog-loading-div'>
            <SpinningCircles className='confirmation-dialog-loading-svg'
                stroke='transparent'
                fill='white'
            />
            <div className='round-background' />
        </div>
    )
}
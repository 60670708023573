import React, { Component } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import './css/ContactDialog.css'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { Bars } from 'react-loading-icons';
import ModalPopup from '../modalPopup/ModalPopup';
import { Close } from '@mui/icons-material'

export class ContactDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            formErrors: { name: '', email: '', phone: '', message: '' },
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submitting: false,
            triggerPopup: false,
            error: false
        }
    }

    setInitialState() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            formErrors: { name: '', email: '', phone: '', message: '' },
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            messageIsValid: false,
            formIsValid: false,
            submitting: false
        }
    }

    handleInput(event, t) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value, t) });
    }



    validateField(fieldName, value, t) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let messageIsValid = this.state.messageIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = value.match(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : value.length > 0 ? `${value} ${t('reservation.form.errors.name')}` : `${t('reservation.form.errors.required')}`;
                break;
            case 'email':
                emailIsValid = value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                fieldValidationErrors.email = emailIsValid ? '' :  value.length > 0 ? `${value} ${t('reservation.form.errors.email')}` : `${t('reservation.form.errors.required')}`
                break;
            case 'phone':
                phoneIsValid = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' :  value.length > 0 ? `${value} ${t('reservation.form.errors.phone')}` : `${t('reservation.form.errors.required')}`
                break;
            case 'message':
                messageIsValid = value.length > 10;
                fieldValidationErrors.message = messageIsValid ? '' : 'Invalid Message'
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            emailIsValid: emailIsValid,
            phoneIsValid: phoneIsValid,
            messageIsValid: messageIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.messageIsValid
        });
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submitting: true });

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/sendContactEmail/`, {
            userName: event.target[0].value,
            userPhone: event.target[2].value,
            userEmail: event.target[1].value,
            message: event.target[3].value
        }, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
            .then((res) => {
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true });
            }, (err) => {
                console.log(err.response);
                this.setState(this.setInitialState);
                this.setState({ triggerPopup: true, error: true })
            });
    }

    closePopup = () => {
        this.setState({ triggerPopup: false }, () => this.props.close())
    }

    render() {
        const { t } = this.props;

        return (
            <Dialog
                maxWidth='md'
                fullScreen={window.innerWidth < 960}
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby="max-width-dialog-title"
                className='reservation-contact-confirmation-Dialog'
            >
                <DialogContent className='reservation-contact-dialog-content'>
                    <Close sx={{ position: 'absolute', top: '10px', right: '10px'}} onClick={this.props.close} />
                    <div className='reservation-contact-dialog-upper'>
                        <p className='reservation-contact-dialog-title'>{t('reservation.contact.title')}</p>
                        <p className='reservation-contact-dialog-text'>{t('reservation.contact.subtitle')}</p>
                    </div>
                    <div className="contacts-form-container">
                        <form className="form-container" onSubmit={this.handleSubmit}>
                            <label className="nome-label">
                                <div className="contacts-label-title">{t('contactos.nome')} <span style={{ fontSize: "11px" }}>{t('contactos.sub-name')}</span></div>
                                <input className={`contacts-input${this.errorClass(this.state.formErrors.name)}`} name="name" placeholder="-"
                                    value={this.state.name}
                                    onChange={(event) => this.handleInput(event, t)} />
                                <p style={{ color: 'red' }}>{this.state.formErrors["name"]}</p>
                            </label>

                            <div className="phone-email-fields">
                                <label className="email-label" htmlFor="email">
                                    <div className="contacts-label-title">EMAIL</div>
                                    <input className={`contacts-input${this.errorClass(this.state.formErrors.email)}`} name="email" placeholder="-"
                                        value={this.state.email}
                                        onChange={(event) => this.handleInput(event, t)} />
                                    <p style={{ color: 'red' }}>{this.state.formErrors["email"]}</p>
                                </label>
                                <label className="phone-label">
                                    <div className="contacts-label-title">{t('contactos.phone')}</div>
                                    <input className={`contacts-input${this.errorClass(this.state.formErrors.phone)}`} name="phone" placeholder="-"
                                        value={this.state.phone}
                                        onChange={(event) => this.handleInput(event, t)} />
                                    <p style={{ color: 'red' }}>{this.state.formErrors["phone"]}</p>
                                </label>
                            </div>

                            <label className="message-label">
                                <div className="contacts-label-title">{t('contactos.mensagem')}</div>
                                <textarea rows={10} className={`contacts-text-area${this.errorClass(this.state.formErrors.message)}`} name="message" placeholder="-"
                                    value={this.state.message}
                                    onChange={(event) => this.handleInput(event, t)} />
                                <p style={{ color: 'red' }}>{this.state.formErrors["message"]}</p>

                            </label>

                            <div className="submitButton-Loading-div">
                                <button type="submit" disabled={!this.state.formIsValid || this.state.submitting}>{t('contactos.botão')}</button>
                                {this.state.submitting ? <Bars className="loading-symbol" /> : null}
                            </div>
                        </form>
                    </div>
                </DialogContent >
                <ModalPopup
                    trigger={this.state.triggerPopup}
                    width="400px"
                    height="400px"
                    title={this.state.error ? t('common.erro') : t('common.sucesso')}
                >
                    <div className="popup-content">
                        <div className="popup-content-text">
                            {!this.state.error ? t('contactos.popupMessage') : t('contactos.popupMessageError')}
                        </div>
                    </div>
                    <div className="popup-buttons">
                        <button className="submit-btn" onClick={this.closePopup}>OK</button>
                    </div>
                </ModalPopup>
            </Dialog >
        )
    }
}

export default withTranslation('translations')(ContactDialog);

import React from 'react';
import './css/commingSoon.css'
import '../../App.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function CommingSoon() {

    const { t } = useTranslation("translations");

    return (
        <div className='notFound-container'>
            <div className='inner-div'>
                <p className='not-found-title'>{t('commingSoon.title')}</p>
                <p className='not-found-text'>{t('commingSoon.subtitle')}</p>
                <Link to='/' className='return-btn'>{t('commingSoon.button')}</Link>
            </div>
        </div>

    )
}

export default CommingSoon

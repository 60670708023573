import { CoworksEN } from '../components/data/Spaces-Data'
import { partnerEN } from '../components/data/parcerias-Data'
import { MobiliarioEN, IluminariaEN, TapecariaEN, DecoracaoEN } from '../components/data/Store-Data'

const english = {

    "navbar": {
        "sobre": "ABOUT",
        "espaços": "COWORK SPACES",
        "parcerias": "PARTNERSHIPS",
        "contactos": "CONTACTS",
        "loja": "STORE",
        "reservar": "RESERVE"
    },

    "sobre": {
        "title1": "COWORK",
        "title1": "COWORK",
        "subtitle1": "COOPERATIVA",
        "headline": `Breathe the fresh air.
        Get inspired by nature.
        And aspire to success…`,
        "text1": `Success at work is said to be 99% perspiration and 1% inspiration. Not here. At Cooperativa Cowork, the concept of rural coworking was raised to a higher level, literally: the top of Serra da Estrela. Places where, for centuries, people and nature have coexisted harmoniously. An environment that allows for unusual shared workspaces, with innovative design and all communication structures, with a high-performance internet system.

        BREATHE – The name is cowork, but we could call it co-happiness. Because it's a pleasure to work in such an environment. Clean air. The green. Or the silence interrupted by birdsong, by the murmur of the wind or by the villagers' daily activities. In the Cowork Cooperative spaces, whenever you need to take a break from work, you just have to jump outside, breathe calmly and recharge your batteries.

        BE INSPIRED – The shared work spaces that make up the Cowork Cooperative are inspiring. The design results from the search for balance between the rural environment and the functionality of the equipment. The pieces were created by designers and produced by artisans or local industry. Each of the spaces results from a close look at human ecology, with a focus on the circular economy and zero kilometer design. Many of the materials used in the spaces are natural, sustainable and from the region, and many pieces can be purchased by order at the Shop on this website.

        ASPIRE SUCCESS – An artistic retreat. A team building activity. A place to write a thesis. At Cooperativa Cowork, spaces offer numerous options for use. More than that, they have the perfect environment for those who want to work in inspiring conditions. The Cowork Cooperative shows that the best way to get out of the comfort zone is to enter an even better comfort zone.`,

        "title2": "ALDEIAS DE MONTANHA",
        "subtitle2": "EMPREENDORISMO",
        "text2": "O espaço de Coworking nas Aldeias de Montanha, procura criar uma oferta dinamizadora que active a vivência da Aldeia, e que ofereça aos seus utilizadores a oportunidade de trabalharem num espaço inovador, inspirador e inusitado.\n\nNos dias de hoje, pela facilidade que temos na conexão ao mundo através da internet, o espaço físico onde trabalhamos deixou de ser um constrangimento, e muitas pessoas procuram terem mais qualidade de vida afastando-se dos grandes centros urbanos, procurando um maior contacto com a natureza.\n\nEste projecto procura também integrar a comunidade local, usando os recursos regionais, como a mão de obra do artesão, ou a peça de artesanato produzido na região, ou re-utilizando objetos em fim de vida, reciclando-os ou restaurando-os, fazendo deste um projecto com uma forte componente ecológica, importando conceitos como o eco design e economia circular.\n\nEstes são espaços têm uma identidade própria, são espaços criativos para pessoas empreendedoras e criativas que procuram uma vida melhor, uma vida mais tranquila, sem perderem a produtividade e a relação com outros pares, com quem podem trocar ideias e experiências. Trata-se de um espaço de cooperação e interacção."
    },

    "espaços": {
        "title": "GET TO KNOW OUR COWORK SPACES",
        "text": `Cooperativa Cowork's work spaces were designed to respond to the most diverse types of requirements. They are non-profit spaces, run by the Mountain Village Network in partnership with local Parish Councils and Municipalities. From the smallest to the most spacious, the proposal involves a balance between working in enviable conditions and enjoying a pleasant and functional rural environment in Serra da Estrela. Think big, because one of these spaces is the perfect answer to your requirements. `
    },

    "parcerias": {
        "text": `When everyone walks in the same direction, the result can only be success.
        Cowork Cooperativa is a project that arose from the idea of ​​stimulating the
        entrepreneurship and develop the economy of the communities that make up the
        network of Mountain Villages. But the paths are easier to walk
        when it is possible to count on partners who share the same interests. 
        Success belongs to everyone.`,
        "institucionais": "PARCERIAS INSTITUCIONAIS ",
        "individuais": "ARTESÃOS E INDUSTRIA LOCAL ",
        "empresas": "SPONSORS ",
        "individuais_cards": { partnerEN }
    },

    "contactos": {
        "title": "CONTACT US",
        "text": "Do you need any help? Feel free to contact us and we will get in touch as soon as possible",
        "nome": "NAME",
        "sub-name": "(FIRST AND LAST)",
        "phone": "CELLPHONE",
        "mensagem": "MESSAGE",
        "botão": "SUBMIT",
        "popupMessage": "Your email was successfully sent.\n\n We will get in touch as soon as possible\n\nThank You",
        "popupMessageError": "We are sorry\n\nWe can't perform this request at the moment\n\nPlease try again later "
    },

    "loja": {
        "mobiliario": "FURNITURE",
        "iluminaria": "LIGHTING",
        "tapeçaria": "TAPESTRY",
        "decoração": "DECORATION",
        "mobiliario_data": { MobiliarioEN },
        "iluminaria_data": { IluminariaEN },
        "tapecaria_data": { TapecariaEN },
        "decoraçao_data": { DecoracaoEN }
    },

    "espaçosDetalhes": {
        "form": {
            "title": "RESERVE",
            "quantidade": "QUANTITY",
            "pessoa": "Person",
            "pessoas": "People",
            "nome": "NAME",
            "sub-name": "(FIRST AND LAST)",
            "localizaçao": "ADDRESS",
            "nif": "VAT NUMBER/PASSPORT",
            "telemovel": "PHONE NUMBER",
            "botão": "SUBMIT",
        },

        "termos": `I read and accept the `,
        "termos_link": "terms and conditions",
        "TERMOS": "Terms and Conditions",
        
        "calendar": {
            "texto": "SELECT THE DAYS ON THE CALENDAR",
            "de": "FROM",
            "ate": "TO"
        },

        "popup": {
            "popupMessage": "Your request was successfully sent.\n\n We will get in touch as soon as possible\n\nThank You",
            "popupMessageError": "We are sorry\n\nWe can't perform this request at the moment\n\nPlease try again later "
        },

        "espaços": { CoworksEN }
    },

    "calendar": {
        "meses": ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'], 
        "semana_long": ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        "semana_short": ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },

    "lojaDetalhes": {
        "dimensoes": "DIMENSIONS",
        "materiais": "MATERIALS",
        "origem": "ORIGIN / ARTISAN",
        "entrega": "DELIVERY TIME",
        "preço": "PRICE",
        "condiçoes": "CONDITIONS",
        "botao": "BUY"
    },

    "validation": {
        "nomeInvalido": "Invalid Name",
        "emailInvalido": "Invalid Email",
        "mensagem": "Write your message, please",
    },

    "common": {
        "sucesso": "Success",
        "erro": "Error",
        "submit": "Submit",
        "cancel": "Cancel"
    },

    "tooltips": {
        "reservar": "Reserve",
        "ver360": "360 View",
        "info": "More info",
        "fechar": "Close",
        "nova-janela": "Open new window"
    },

    "commingSoon": {
        "title": "Soon",
        "subtitle": "This page is under construction",
        "button": "Back"
    },

    "privacidade": "Privacy Policy",

    "feedback": {
        "title": "Give us your feedback",
        "subtitle": "We much apreciate your feedback about your time in one of our coworks",
        "field": {
            "name": "Name",
            "question1": "How was your experience overall?",
            "question2": "Thinking about your work experience in the Cowork @Aldeias de Montanha space, do you have any comments on how we could improve your experience on your next visit?",
            "question3": "What is the probability of working on this cowork again?",
            "question4": "What is the probability of recommending the Cowork @Mountain Villages space to a friend or family member?",
            "question5": "How do you rate the quality of the internet and other available devices?",
            "question6": "Do you think you had everything you needed in the cowork space? If not, what would you add in this space (eg. appliances, printers...)?"
        },
        "button": "Send Feedback",
        "ratingLevels": {
            "1": "Horrible",
            "2": "Very Bad",
            "3": "Bad",
            "4": "Not so great",
            "5": "More or Less",
            "6": "Good",
            "7": "Very Good",
            "8": "Loved It",
            "9": "Excellent",
            "10": "Magnificent",
        },
        "ratingLevels2": {
            "1": "None",
            "2": "Unlikely",
            "3": "Probable",
            "4": "Very likely",
            "5": "Certainly",
        },
        "ratingLevels3": {
            "1": "None",
            "2": "Unlikely",
            "3": "Probable",
            "4": "Very likely",
            "5": "Certainly",
        },
        "ratingLevels4": {
            "1": "Very Bad",
            "2": "Bad",
            "3": "Reasonable",
            "4": "Good",
            "5": "Very Good",
        },
        "confirmationModal": {
            "success": "Thank you very much for your Feedback, we hope to see you again.",
            "fail": "It was not possible to send your feedback, please try again Later. If the problems persists, contact us."
        }
    },

    "feedbackGiven": {
        "title": "Thank you for your feedback",
        "subtitle": "We appreciate the time you took to give us some feedback, we hope to see you soon"
    },

    "hourpicker": {
        "title": 'Pick starting and ending hour',
        "note": "All dates are to be considered GMT +00:00",
    },

    "reservation": {
        "dateContainer": {
            "title": "Start by choosing the number of people, then select the days in the calendar and then pick the hours ",
            "notAvailableMessage": "Your selection of days contain days that are not available, please choose again",
            "people": {
                "single": "Person",
                "plural": "People",
                "title": "Select the number of people",
                "person": 'Person selected',
                "people": "People selected"
            },
            "calendar": {
                "title": "Click once to select the check-in day and again to select the check-out day",
                "disclaimer": "Days greyed out are not available or already booked"
            },
            "hours": {
                "title": "Select check-in hour and then select the check-out hour",
                "disclaimer": "hours greyed out are not available or already booked",
                "subtitle": "Select a day on the calendar",
                "checkInSubtitle":"Select a check-in hour",
                "checkOutSubtitle": "Select a check-out hour",
                "tooltip": " people already reserved this hour",
                "tooltipEmpty": "There are no reservations at this hour",
                "seatsOccupied": "Occupied seats",
                "unavailable": {
                    "first": "There are no available seats on the selected days",
                    "second": "has no available seats"
                }
            },
            "addMore": "Add more dates",
        },
        "topInfo": {
            "checkIn": "Check-in",
            "checkOut": "Check-out",
            "people": "Number of people"
        },
        "form": {
            "title": "Fill the following form",
            "subtitle": "Please provide your information through the form",
            "name": {
                "first": "Name",
                "second": "(First and Last)"
            },
            "address": "Location",
            "phone": "Phone number",
            "nif": "vat number/passport",
            "message": "Observations/Requests",
            "messagePlaceholder": "Write here if you have any special requests or if you would like to make any comments about your booking",
            "errors": {
                "required": "This field is required",
                "name": "is not a valid name",
                "address": "is not a valid location, write only the location/city, eg: London",
                "nif": "is not a valid VAT / Passport number",
                "phone": "is not a valid phone number",
                "email": "is not a valid email",
            },
            "required": "Required Field"
        },
        "review": {
            "title": "Reservation Review",
            "subtitle": "Review the reservation information as well as the information from the form on the left",
            "checkIn": "Check-in",
            "checkOut": "Check-out",
            "people": "Number of people",
            "nPeople": "People",
            "location": "Location",
            "note": {
                "title": "Importante Note",
                "text": "This reservation is only valid for the cowork space, not including bedrooms reservation if applicable in this cowork, if you would like to reserve bedrooms contact us through the contact form on this website"
            },
            "shareNote": {
                "title": "Share Data",
                "text": "Do you authorize the sharing of personal data (email, name) for future interviews and/or academic work?",
                "yes": "Yes",
                "no": "No"
            }
        },
        "actions": {
            "tnc": {
                "first": "I read and accept the ",
                "second": "terms and conditions"
            },
            "button": "Submit reservation"
        },
        "confirmation": {
            "titles": {
                "title1": "Registering your reservation...",
                "title2": "Reservation was created",
                "title3": "Error while creating reservation"
            },
            "body": {
                "body1": "Please wait while we register your reservation",
                "body2": "Your registration was successfully created, you should soon receive an email confirming your reservation with all the details.\n\nEnjoy your time at",
                "body3": "Erro registering your reservation, please try again later\n\n"
            }
        },
        "contact" : {
            "link1": "Do you have a large group or any special request?",
            "link2": " so we can help you.",
            "span": "Click here",
            "title": "Special Reservation",
            "subtitle": "If you would like to make a reservation but have a large group or need more information, fill out this form and tell us all the details and requirements so that we can help you in the best way"
        }
    }
}

export default english;
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from "react-i18next"
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';

import './tncmodalpopup.css'
import '../../App.css'

function TnCModalPopup(props) {

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [t, i18n] = useTranslation('translations');

    return (props.trigger) ? (
        <div className="tnc-background-div" >
            <div className="tnc-popup">
                <div className="tnc-navigation-div">
                    <p>{t('espaçosDetalhes.TERMOS')} <br></br> {props.data.title}, {props.data.location}</p>
                    <div className="tnc-btn-div">
                        <div data-tip data-for='close-btn' className="fas fa-window-close" onClick={props.close}></div>
                    </div>
                </div>
                <div className="content-div">
                    <Document
                        
                        file={`${process.env.REACT_APP_SERVER_URL}/resources/files/coworks/${i18n.language === 'pt' ? props.data.tncPDFpt : props.data.tncPDFen }`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading='Aguarde por favor...'
                        
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={1000} renderTextLayer={false} />
                        ))}
                        
                    </Document>
                </div>
            </div>
            <ReactTooltip id="close-btn" effect="solid">
                <span>{t('tooltips.fechar')}</span>
            </ReactTooltip>
        </div>
    ) : ''
}

export default TnCModalPopup

import React from 'react'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from "react-i18next"
import './vrmodalpopup.css'
import '../../App.css'

function VrModalPopup(props) {

    const { t } = useTranslation("translations");

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (props.trigger) ? (
        <div className="vr360-view-background-div" >
            <div className="vr360-view-popup">
                <div className="vr360-view-navigation-div">
                    <p>COOPERATIVA {props.data.title}, {props.data.location}</p>
                    <div className="vr360-view-btn-div">
                        <div data-tip data-for='new-window-btn' className="fas fa-window-restore" onClick={() => openInNewTab(props.data.vrUrl)}></div>
                        <div data-tip data-for='close-btn' className="fas fa-window-close" onClick={props.close}></div>
                    </div>
                </div>
                <div className="iframe-div">
                    <iframe 
                    src={props.data.vrUrl} 
                    referrerPolicy="no-referrer" 
                    allowFullScreen
                    title="title test"
                    style={{ width:"100%", height:"100%"}}>
                    </iframe>
                </div>
            </div>
            <ReactTooltip id="new-window-btn" effect="solid">
                <span>{t('tooltips.nova-janela')}</span>
            </ReactTooltip>
            <ReactTooltip id="close-btn" effect="solid">
                <span>{t('tooltips.fechar')}</span>
            </ReactTooltip>
        </div>
    ) : ''
}

export default VrModalPopup

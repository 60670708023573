import React from 'react'
import './modalpopup.css'

function ModalPopup(props) {
    return (props.trigger) ? (
        <div className="background-div" >
            <div className="popup" style={{ width: props.width, height: props.height }}>
                <div className="popup-title">
                    <p>{props.title}</p>
                </div>
                {props.children}
            </div>
        </div>
    ) : ''
}

export default ModalPopup

import axios from 'axios'
import { paix } from 'paix'

import Teleperformance_Image from '../../assets/images/logos/LOGO_PARCERIA.png'

import Seia from '../../assets/images/logos/svg/INSTITUCIONAIS-Seia.svg'
import OHospital from '../../assets/images/logos/svg/INSTITUCIONAIS-OHospital.svg'
import Guarda from '../../assets/images/logos/svg/INSTITUCIONAIS-Guarda.svg'
import Gouveia from '../../assets/images/logos/svg/INSTITUCIONAIS-Gouveia.svg'
import Fundão from '../../assets/images/logos/svg/INSTITUCIONAIS-Fundao.svg'

const tempObject = { partnersPT: [], partnersEN: [] }

function fetchData() {

    return axios.get(`${process.env.REACT_APP_SERVER_URL}/api/partnerships`, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
        .then((res) => {
            tempObject.partnersPT = [...res.data];
            tempObject.partnersEN = [...res.data];
        }, (err) => {
            console.log(err)
        });
}

const transformData = () => {
    const PT_replacement = { profession_PT: 'profession', description_PT: 'description' };
    const EN_replacement = { profession_EN: 'profession', description_EN: 'description' }

    tempObject.partnersPT.forEach((element, index) => {
        tempObject.partnersPT[index] = (({ id, name, location, portrait, full_image, profession_PT, webpage, description_PT }) => ({ id, name, location, portrait, full_image, profession_PT, webpage, description_PT }))(element);
        tempObject.partnersPT[index] = paix(tempObject.partnersPT[index], PT_replacement)
    });

    tempObject.partnersEN.forEach((element, index) => {
        tempObject.partnersEN[index] = (({ id, name, location, portrait, full_image, profession_EN, webpage, description_EN }) => ({ id, name, location, portrait, full_image, profession_EN, webpage, description_EN }))(element);
        tempObject.partnersEN[index] = paix(tempObject.partnersEN[index], EN_replacement)
    });
}

fetchData()
    .then(() => {
        transformData();
        partnerPT.partners = tempObject.partnersPT;
        partnerEN.partners = tempObject.partnersEN;
    })

export const partnerPT = { partners: [] };
export const partnerEN = { partners: [] };

export const empresas = [
    {
        name: "Teleperformance",
        image_url: Teleperformance_Image,
        link: "https://pt.www.teleperformance.com/pt-pt/quem-somos/sobre-nos"
    },
]

export const institucionais = [
    {
        name: 'Fundão',
        image: Fundão,
        link: '',
        height: '100px'
    },
    {
        name: 'Seia',
        image: Seia,
        link: '',
        height: '175px'
    },
    {
        name: 'Oliveira do Hospital',
        image: OHospital,
        link: '',
        height: '175px'
    },
    {
        name: 'Guarda',
        image: Guarda,
        link: '',
        height: '175px'
    },
    {
        name: 'Gouveia',
        image: Gouveia,
        link: '',
        height: '175px'
    },

]

import React, { useState, useEffect } from 'react'
import './css/ItemDetails.css'
import '../../App.css'
import { useTranslation } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './css/ItemDetailsSlider.css'
import { Redirect } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function ItemDetails(props) {

    const item = props.location.state === undefined ? JSON.parse(localStorage.getItem('shop_item')) : props.location.state.item;
    
    const { t } = useTranslation("translations");
    const [submiting, setSubmiting] = useState(false);
    
    window.addEventListener("beforeunload", (event) => {
        event.preventDefault();
        localStorage.removeItem("shop_item");
    })

    useEffect(() => { 
        window.scrollTo(0,0);
    })
    
    if (item === null) {
        return (
            <Redirect to="/loja" />
        )
    }

    const handleSubmit = event => {
        event.preventDefault();
        setSubmiting(true);

        setTimeout(() => {
            setSubmiting(false);

            alert(
                `Nome do Produto: ${item.name}
                Dimensões: ${item.dimensions}
                Materiais: ${item.materials}
                Origem: ${item.origin}
                Tempo de entrega: ${item.deliveryTime}
                Preço: ${item.price}`
            )

        }, 1000);
    }

    return (
        <div className="item-details-container">
            <div className="item-details-left-container">
                <div className="item-details-info">
                    <div className="item-details-title">{item.name.toUpperCase()}</div>
                    <div>
                        <p className="item-details-subtitle">{t('lojaDetalhes.dimensoes')}</p>
                        <p className="item-details-text">{item.dimensions}</p>
                    </div>
                    <div>
                        <p className="item-details-subtitle">{t('lojaDetalhes.materiais')}</p>
                        <p className="item-details-text">{item.materials}</p>
                    </div>
                    <div>
                        <p className="item-details-subtitle">{t('lojaDetalhes.origem')}</p>
                        <p className="item-details-text">{item.origin}</p>
                    </div>
                    <div>
                        <p className="item-details-subtitle">{t('lojaDetalhes.entrega')}</p>
                        <p className="item-details-text">{item.deliveryTime}</p>
                    </div>
                    <div>
                        <p className="item-details-subtitle">{t('lojaDetalhes.preço')}</p>
                        <p className="item-details-text">{item.price}</p>
                    </div>
                    <button disabled={submiting} onClick={handleSubmit}>{t('lojaDetalhes.botao')}</button>
                </div>
                <div className="item-details-condition">
                    <p className="item-details-subtitle">{t('lojaDetalhes.condiçoes')}</p>
                    <p className="item-details-condition-text">{item.condition}</p>
                </div>
            </div>
            <div className="item-details-right-container">
                    <AutoplaySlider
                        fillParent={ window.screen.width > 960 ? true : false }
                        bullets={true}
                        organicArrows={false}
                        play={true}
                        interval={3000}
                        cancelOnInteraction={false}
                    >
                        <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${item.sliderImage1}`} />
                        <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${item.sliderImage2}`} />
                        <div data-src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${item.sliderImage3}`} />
                    </AutoplaySlider>
            </div>
        </div>
    )
}

export default ItemDetails;

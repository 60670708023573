import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ArrowDownward } from '@mui/icons-material'

import './css/TopInfoBar.css'


function TopInfoBar({ cowork, state, ...props }) {
    const { t } = useTranslation('translations')
    const [dates, setDates] = useState([])
    const topInfoRef = useRef(null)
    const formContainer = props.formRef.current

    const observer = new IntersectionObserver(entries => {
        if (topInfoRef.current) {
            topInfoRef.current.classList.toggle("show", entries[0].isIntersecting)
        }
    }, { threshold: 0.7, rootMargin: '0px 0px' })

    useEffect(() => {
        setDates(state.dates.filter(date => date.filled))
    }, [state])

    useEffect(() => {
        if (formContainer && topInfoRef) observer.observe(formContainer);
        return () => {
            if (formContainer) observer.unobserve(formContainer)
        }
    }, [topInfoRef, formContainer])

    return (
        <div className={`top-info-bar-main-div`} ref={topInfoRef}>
            {dates.length > 1 &&
                <div className='top-info-more-text'>
                    <ArrowDownward sx={{ scale: '0.8' }} />
                    <p>{`Mais ${dates.length - 1} datas selecionadas`}</p>
                    <ArrowDownward sx={{ scale: '0.8' }} />
                </div>
            }
            <ReviewLabels title='Cowork'>{cowork.title}</ReviewLabels>
            <div className='top-info-dates-wrapper'>
                {dates.map((date, index) => {
                    return (
                        <div className='top-info-dates-div' key={index}>
                            <ReviewLabels title={t('reservation.topInfo.checkIn')}>{moment(date.checkIn).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</ReviewLabels>
                            <ReviewLabels title={t('reservation.topInfo.checkOut')}>{moment(date.checkOut).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</ReviewLabels>
                            <ReviewLabels title={t('reservation.topInfo.people')}>{date.people}</ReviewLabels>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TopInfoBar

const ReviewLabels = ({ title, children }) => {
    return (
        <div className='review-labels-div' style={{ margin: 'auto', width: '30%' }}>
            <p className='review-labels-title' style={{ textAlign: 'center' }}>{title}</p>
            <p className='review-labels-text' style={{ textAlign: 'center' }}>{children}</p>
        </div>
    )
}
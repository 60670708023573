import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import '../../App.css';
import './css/Loja.css'

function Loja() {

    const { t } = useTranslation("translations");

    const mobiData = t('loja.mobiliario_data', { returnObjects: true });
    const mobiFinalData = mobiData[Object.keys(mobiData)[0]];
    
    const iluData = t('loja.iluminaria_data', { returnObjects: true });
    const iluFinalData = iluData[Object.keys(iluData)[0]];

    const tapData = t('loja.tapecaria_data', { returnObjects: true });
    const tapFinalData = tapData[Object.keys(tapData)[0]];

    const decData = t('loja.decoraçao_data', { returnObjects: true });
    const decFinalData = decData[Object.keys(decData)[0]];

    return (
        <div className="loja-container">
            <Link className="loja-mobiliario"
                to={{
                    pathname: "/loja/lojadetalhes",
                    state: { mobiFinalData }
                }}
                onAuxClick={() => localStorage.setItem('store_category', JSON.stringify(mobiFinalData))}
            >
                <div className="plus-sign"></div>
                <div className="title-container">
                    <div className="title-loja"><p>{t('loja.mobiliario')}</p></div>
                </div>
            </Link>

            <Link className="loja-iluminaria"
                to={{
                    pathname: "/loja/lojadetalhes",
                    state: { iluFinalData }
                }}
                onAuxClick={() => localStorage.setItem('store_category', JSON.stringify(iluFinalData))}
            >
                <div className="plus-sign"></div>
                <div className="title-container">
                    <div className="title-loja"><p>{t('loja.iluminaria')}</p></div>
                </div>
            </Link>

            <Link className="loja-tapecaria"
                to={{
                    pathname: "/loja/lojadetalhes",
                    state: { tapFinalData }
                }}
                onAuxClick={() => localStorage.setItem('store_category', JSON.stringify(tapFinalData))}
            >
                <div className="plus-sign"></div>
                <div className="title-container">
                    <div className="title-loja"><p>{t('loja.tapeçaria')}</p></div>
                </div>
            </Link>

            <Link className="loja-decoraçao"
                to={{
                    pathname: "/loja/lojadetalhes",
                    state: { decFinalData }
                }}
                onAuxClick={() => localStorage.setItem('store_category', JSON.stringify(decFinalData))}
            >
                <div className="plus-sign"></div>
                <div className="title-container">
                    <div className="title-loja"><p>{t('loja.decoração')}</p></div>
                </div>
            </Link>
        </div>
    )
}

export default Loja;
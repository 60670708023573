import React, { useState } from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import './CustomRating.css'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement='top' {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(text-dark)',
        fontSize: '18px',
        color: 'white',
        border: 'none',
    },
}));

function CustomRating({ max, value, onChange, icon, emptyIcon }) {
    const [innerValue, setInnerValue] = useState(value - 1)

    const checkIfIconInsideValue = (index) => {
        return value >= index + 1
    }

    const handleMouseHover = (e, index) => {
        if (e.type === 'mouseenter') {
            setInnerValue(index)
            return
        }
        setInnerValue(value - 1)
    }

    return (
        <StyledTooltip title={innerValue + 1}>
            <div className='custom-rating-main-div'>
                {Array.from({ length: max }).map((elem, index) => {
                    return (
                        <div
                            className={`custom-rating-icon-div ${checkIfIconInsideValue(index) ? 'filled' : ''}`}
                            key={index}
                            onClick={() => onChange(index + 1)}
                            onMouseEnter={(e) => handleMouseHover(e, index)}
                            onMouseLeave={(e) => handleMouseHover(e, index)}
                        >
                            {checkIfIconInsideValue(index) || innerValue >= index ? icon : emptyIcon}
                        </div>
                    )
                })}
            </div>
        </StyledTooltip>
    )
}

export default CustomRating


export const CompactRating = ({ value, max, t }) => {

    const getPercentage = () => {
        return value / max * 100
    }

    return (
        <div className='compact-rating-main-div'>
            <p className='compact-rating-text' >{`${value}/${max} ${t('reservation.dateContainer.hours.seatsOccupied')}`}</p>
            <div className='compact-rating-fill-bar' style={{ width: `${getPercentage()}%` }}/>
        </div>
    )
}
import axios from 'axios'
import { paix } from 'paix'

const tempObject = { coworksEN: [], coworksPT: []}

async function fetchData() {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/coworks`, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
        .then((res) => {
            tempObject.coworksPT = [...res.data]
            tempObject.coworksEN = [...res.data]
        }, (err) => {
            console.log(err)
        });
}

const transformData = () => {
    const PT_replacement = { short_description_PT: 'shortDescription', full_description_PT: 'fullDescription' };
    const EN_replacement = { short_description_EN: 'shortDescription', full_description_EN: 'fullDescription' }

    tempObject.coworksPT.forEach((element, index) => {
        tempObject.coworksPT[index] = (({ id, title, location_id, online, location, tncPDFpt, tncPDFen, vrUrl, portrait, sliderImage1, sliderImage2, sliderImage3, blueprint, short_description_PT, full_description_PT, capacity, address, coordinates, amenities }) => ({ id, title, location_id, online, location, tncPDFpt, tncPDFen, vrUrl, portrait, sliderImage1, sliderImage2, sliderImage3, blueprint, short_description_PT, full_description_PT, capacity, address, coordinates, amenities }))(element);
        tempObject.coworksPT[index] = paix(tempObject.coworksPT[index], PT_replacement)
    });

    tempObject.coworksEN.forEach((element, index) => {
        tempObject.coworksEN[index] = (({ id, title, location_id, online, location, tncPDFpt, tncPDFen, vrUrl, portrait, sliderImage1, sliderImage2, sliderImage3, blueprint, short_description_EN, full_description_EN, capacity, address, coordinates, amenities }) => ({ id, title, location_id, online, location, tncPDFpt, tncPDFen, vrUrl, portrait, sliderImage1, sliderImage2, sliderImage3, blueprint, short_description_EN, full_description_EN, capacity, address, coordinates, amenities }))(element);
        tempObject.coworksEN[index] = paix(tempObject.coworksEN[index], EN_replacement)
    });
}

fetchData()
.then(() => {
        transformData();
        CoworksPT.coworks = tempObject.coworksPT;
        CoworksEN.coworks = tempObject.coworksEN;
    })

export const CoworksPT = { coworks: [] };
export const CoworksEN = { coworks: [] };

import React, { Component } from 'react';
import './FeedbackForm.css'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { Bars } from 'react-loading-icons';
import ModalPopup from '../modalPopup/ModalPopup';
import moment from 'moment';
import { Divider, Rating, Box } from '@mui/material'

import StarIcon from '@mui/icons-material/Star';

export class FeedbackForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reservationID: props.data.id,
            name: props.data.client_name,
            email: props.data.client_email,
            quantity: props.data.quantity,
            cowork: props.data.cowork,
            checkIn: moment(props.data.checkIn),
            checkOut: moment(props.data.checkOut),
            message: '',
            rating: 2.5,
            ratingHover: -1,
            ratingHover2: -1,
            ratingHover3: -1,
            ratingHover4: -1,
            ratingMessage: '',
            secondMessage: '',
            thirdMessage: '',
            rating2: 3,
            rating3: 3,
            rating4: 3,
            formIsValid: true,
            submiting: false,
            triggerPopup: false,
            error: false
        }
    }

    errorClass(error) {
        return (!error ? '' : 'error')
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submiting: true });

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/feedbackemail`, {
            reservation_id: this.state.reservationID,
            client_name: this.state.name,
            client_email: this.state.email,
            quantity: this.state.quantity,
            cowork: this.state.cowork,
            checkIn: this.state.checkIn,
            checkOut: this.state.checkOut,
            rating: this.state.rating,
            rating2: this.state.rating2,
            rating3: this.state.rating3,
            rating4: this.state.rating4,
            firstMessage: this.state.ratingMessage,
            secondMessage: this.state.secondMessage,
            thirdMessage: this.state.thirdMessage
        }, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
            .then((res) => {
                this.setState({ triggerPopup: true, submiting: false });
            }, (err) => {
                console.log(err.response);
                this.setState({ triggerPopup: true, error: true, submiting: false })
            })
    }

    closePopup = () => {
        this.setState({ triggerPopup: false }, () => this.props.redirect())
    }

    render() {
        const { t } = this.props;

        const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
            if (newWindow) newWindow.opener = null
        }

        const labels = {
            0.5: t('feedback.ratingLevels.1'),
            1: t('feedback.ratingLevels.2'),
            1.5: t('feedback.ratingLevels.3'),
            2: t('feedback.ratingLevels.4'),
            2.5: t('feedback.ratingLevels.5'),
            3: t('feedback.ratingLevels.6'),
            3.5: t('feedback.ratingLevels.7'),
            4: t('feedback.ratingLevels.8'),
            4.5: t('feedback.ratingLevels.9'),
            5: t('feedback.ratingLevels.10'),
        };

        const labels2 = {
            1: t('feedback.ratingLevels2.1'),
            2: t('feedback.ratingLevels2.2'),
            3: t('feedback.ratingLevels2.3'),
            4: t('feedback.ratingLevels2.4'),
            5: t('feedback.ratingLevels2.5'),
        };

        const labels3 = {
            1: t('feedback.ratingLevels3.1'),
            2: t('feedback.ratingLevels3.2'),
            3: t('feedback.ratingLevels3.3'),
            4: t('feedback.ratingLevels3.4'),
            5: t('feedback.ratingLevels3.5'),
        };

        const labels4 = {
            1: t('feedback.ratingLevels4.1'),     
            2: t('feedback.ratingLevels4.2'),    
            3: t('feedback.ratingLevels4.3'),        
            4: t('feedback.ratingLevels4.4'),
            5: t('feedback.ratingLevels4.5'),
        };

        //init(process.env.REACT_APP_USER_ID);

        return (
            <div className="feedback-form-container">
                <form className="feedback-form-subcontainer" onSubmit={this.handleSubmit}>

                    <div className="feedback-phone-email-fields">
                        <label className="nome-label">
                            <div className="feedback-label-title">{t('feedback.field.name')}</div>
                            <input className={`feedback-input`} name="name" placeholder="-" value={this.state.name} disabled />
                        </label>
                        <label className="nome-label">
                            <div className="feedback-label-title">Cowork</div>
                            <input className={`feedback-input`} name="cowork" placeholder="-" value={this.state.cowork} disabled />
                        </label>
                    </div>

                    <div className="feedback-phone-email-fields">
                        <label className="nome-label">
                            <div className="feedback-label-title">Check In</div>
                            <input className={`feedback-input`} name="name" placeholder="-" value={this.state.checkIn.format("ddd, DD MMMM YYYY, HH:mm")} disabled />
                        </label>
                        <label className="nome-label">
                            <div className="feedback-label-title">Check Out</div>
                            <input className={`feedback-input`} name="cowork" placeholder="-" value={this.state.checkOut.format("ddd, DD MMMM YYYY, HH:mm")} disabled />
                        </label>
                    </div>

                    <Divider style={{ marginBottom: '25px' }} />

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question1')}</p>
                        <Rating
                            name='rating'
                            value={this.state.rating}
                            precision={0.5}
                            onChange={(event, newValue) => this.setState({ rating: newValue })}
                            onChangeActive={(event, newHover) => this.setState({ ratingHover: newHover })}
                            emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize='inherit' />}
                            size='large'
                        />
                        {this.state.rating !== null && (
                            <Box>{labels[this.state.ratingHover !== -1 ? this.state.ratingHover : this.state.rating]}</Box>
                        )}
                        <textarea rows={2} className={`feedback-text-area`} name="ratingMessage" placeholder="-"
                            value={this.state.ratingMessage}
                            onChange={(event) => this.setState({ ratingMessage: event.target.value })} />
                    </div>

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question2')}</p>
                        <textarea rows={2} className={`feedback-text-area`} name="ratingMessage" placeholder="-"
                            value={this.state.secondMessage}
                            onChange={(event) => this.setState({ secondMessage: event.target.value })} />
                    </div>

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question3')}</p>
                        <Rating
                            name='rating'
                            value={this.state.rating2}
                            precision={1}
                            onChange={(event, newValue) => this.setState({ rating2: newValue })}
                            onChangeActive={(event, newHover) => this.setState({ ratingHover2: newHover })}
                            emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize='inherit' />}
                            size='large'
                        />
                        {this.state.rating2 !== null && (
                            <Box>{labels2[this.state.ratingHover2 !== -1 ? this.state.ratingHover2 : this.state.rating2]}</Box>
                        )}
                    </div>

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question4')}</p>
                        <Rating
                            name='rating'
                            value={this.state.rating3}
                            precision={1}
                            onChange={(event, newValue) => this.setState({ rating3: newValue })}
                            onChangeActive={(event, newHover) => this.setState({ ratingHover3: newHover })}
                            emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize='inherit' />}
                            size='large'
                        />
                        {this.state.rating3 !== null && (
                            <Box>{labels3[this.state.ratingHover3 !== -1 ? this.state.ratingHover3 : this.state.rating3]}</Box>
                        )}
                    </div>

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question5')}</p>
                        <Rating
                            name='rating'
                            value={this.state.rating4}
                            precision={1}
                            onChange={(event, newValue) => this.setState({ rating4: newValue })}
                            onChangeActive={(event, newHover) => this.setState({ ratingHover4: newHover })}
                            emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize='inherit' />}
                            size='large'
                        />
                        {this.state.rating4 !== null && (
                            <Box>{labels4[this.state.ratingHover4 !== -1 ? this.state.ratingHover4 : this.state.rating4]}</Box>
                        )}
                    </div>

                    <div className='ratingDiv'>
                        <p className="feedback-label-title-2">{t('feedback.field.question6')}</p>
                        <textarea rows={2} className={`feedback-text-area`} name="ratingMessage" placeholder="-"
                            value={this.state.thirdMessage}
                            onChange={(event) => this.setState({ thirdMessage: event.target.value })} />
                    </div>

                    <p onClick={() => openInNewTab('https://mailchi.mp/4c4c0d80faf4/questionrio-de-satisfao-espaos-cooperativa-cowork')}>Questionario</p>
                    <div className="feedback-submitButton-Loading-div">
                        <button type="submit" disabled={!this.state.formIsValid || this.state.submiting}>{t('feedback.button')}</button>
                        {this.state.submiting ? <Bars className="feedback-loading-symbol" /> : null}
                    </div>
                </form>

                <ModalPopup
                    trigger={this.state.triggerPopup}
                    width="400px"
                    height="400px"
                    title={this.state.error ? t('common.erro') : t('common.sucesso')}
                >
                    <div className="popup-content">
                        <div className="popup-content-text">
                            {!this.state.error ? t('feedback.confirmationModal.success') : t('feedback.confirmationModal.fail')}
                        </div>
                    </div>
                    <div className="popup-buttons">
                        <button className="submit-btn" onClick={this.closePopup}>OK</button>
                    </div>
                </ModalPopup>

            </div>
        )
    }
}

export default withTranslation('translations')(FeedbackForm);

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { debounce } from '../../utils'

import './css/FormContainer.css'

class FormContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			nome: '',
			email: '',
			phone: '',
			location: '',
			nif: '',
			formErrors: { nome: '', email: '', phone: '', location: '', nif: '' },
			nomeIsValid: false,
			emailIsValid: false,
			phoneIsValid: false,
			locationIsValid: true,
			nifIsValid: true,
			formIsValid: false,
			submitting: false,
			triggerTnCModal: false,
			error: false
		}
	}

	setInitialState = () => {
		return {
			nome: '',
			email: '',
			phone: '',
			location: '',
			nif: '',
			formErrors: { nome: '', email: '', phone: '', location: '', nif: '' },
			nomeIsValid: false,
			emailIsValid: false,
			phoneIsValid: false,
			locationIsValid: true,
			nifIsValid: true,
			formIsValid: false,
			submitting: false,
		}
	}

	handleInput(event, t) {
		const name = event.target.name;
		const value = event.target.value;

		this.setState({ [name]: value }, () => this.debounceInput(name, value, t));
		this.props.updateParentState(name, value)
	}

	debounceInput = debounce((name, value, t) => {
		this.validateField(name, value, t)
	}, 500)

	validateField(fieldName, value, t) {
		let fieldValidationErrors = this.state.formErrors;
		let nomeIsValid = this.state.nomeIsValid;
		let emailIsValid = this.state.emailIsValid;
		let phoneIsValid = this.state.phoneIsValid;
		let locationIsValid = this.state.locationIsValid;
		let nifIsValid = this.state.nifIsValid;

		switch (fieldName) {
			case 'nome':
				nomeIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
				fieldValidationErrors.nome = nomeIsValid ? '' : value.length > 0 ? `${value} ${t('reservation.form.errors.name')}` : `${t('reservation.form.errors.required')}`;
				break;
			case 'email':
				emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				fieldValidationErrors.email = emailIsValid ? '' : value.length > 0 ? `${value} ${t('reservation.form.errors.email')}` : `${t('reservation.form.errors.required')}`;
				break;
			case 'phone':
				phoneIsValid = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(value) && (value.length >= 9);
				fieldValidationErrors.phone = phoneIsValid ? '' : value.length > 0 ? `${value} ${t('reservation.form.errors.phone')}` : `${t('reservation.form.errors.required')}`;
				break;
			case 'location':
				locationIsValid = (new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 2) || value === '';
				fieldValidationErrors.location = locationIsValid ? '' : `${value} ${t('reservation.form.errors.address')}`;
				break;
			case 'nif':
				nifIsValid = new RegExp(/^[a-zA-Z0-9]*$/).test(value) || value === '';
				fieldValidationErrors.nif = nifIsValid ? '' : `${value} ${t('reservation.form.errors.nif')}`;
				break;
			default:
				break;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			nomeIsValid: nomeIsValid,
			emailIsValid: emailIsValid,
			phoneIsValid: phoneIsValid,
			locationIsValid: locationIsValid,
			nifIsValid: nifIsValid
		}, this.validateForm)

	}

	validateForm() {
		const isFormValid = () => {
			return this.state.nomeIsValid &&
				this.state.emailIsValid &&
				this.state.phoneIsValid &&
				this.state.locationIsValid &&
				this.state.nifIsValid
		}

		this.setState({ formIsValid: isFormValid() }, () => this.props.updateParentState('formIsValid', isFormValid()));
	}

	errorClass(error) {
		return (!error ? '' : 'error')
	}

	render() {
		const { t } = this.props;

		return (
			<div className="reservation-form-container">
				<div className='form-info-title-div'>
					<p className='form-title'>{t('reservation.form.title')}</p>
					<p className='form-subtitle'>{t('reservation.form.subtitle')}</p>
				</div>
				<div className="reservation-form-input-fields">
					<label className="nome-label">
						<div className="reservation-form-label-title">{t('reservation.form.name.first')} <span style={{ fontSize: "11px" }}>{t('reservation.form.name.second')}</span>*</div>
						<input className={`input${this.errorClass(this.state.formErrors.nome)}`} name="nome" placeholder="-"
							value={this.state.nome}
							onChange={(event) => this.handleInput(event, t)} />
						<p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["nome"]}</p>
					</label>

					<label className="location-label" >
						<div className="reservation-form-label-title">{t('reservation.form.address')}</div>
						<input className={`input${this.errorClass(this.state.formErrors.location)}`} name="location" placeholder="-"
							value={this.state.location}
							onChange={(event) => this.handleInput(event, t)} />
						<p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["location"]}</p>
					</label>

					<label className="nif-label" >
						<div className="reservation-form-label-title">{t('reservation.form.nif')}</div>
						<input className={`input${this.errorClass(this.state.formErrors.nif)}`} name="nif" placeholder="-"
							value={this.state.nif}
							onChange={(event) => this.handleInput(event, t)} />
						<p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["nif"]}</p>
					</label>

					<label className="phone-label" >
						<div className="reservation-form-label-title">{t('reservation.form.phone')}*</div>
						<input className={`input${this.errorClass(this.state.formErrors.phone)}`} name="phone" placeholder="-"
							value={this.state.phone}
							onChange={(event) => this.handleInput(event, t)} />
						<p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["phone"]}</p>
					</label>

					<label className="email-label" htmlFor="email">
						<div className="reservation-form-label-title">EMAIL*</div>
						<input className={`input${this.errorClass(this.state.formErrors.email)}`} name="email" placeholder="-"
							value={this.state.email}
							onChange={(event) => this.handleInput(event, t)} />
						<p style={{ color: "rgb(170, 38, 38)" }}>{this.state.formErrors["email"]}</p>
					</label>
					<label className="message-label">
						<div className="reservation-form-label-title">{t('reservation.form.message')}</div>
						<textarea rows={7} className='reservation-form-text-area ' name="message" placeholder={`${t('reservation.form.messagePlaceholder')}`}
							value={this.state.message}
							onChange={(event) => this.props.updateParentState(event.target.name, event.target.value)}
						/>
					</label>
					<p className='form-required-text'>{`* ${t('reservation.form.required')}`}</p>
				</div>
			</div>
		)
	}
}


export default withTranslation('translations')(FormContainer);

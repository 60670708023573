import React from 'react';
import '../../App.css';
import './css/About.css'
import { useTranslation } from "react-i18next"
import Logo from '../../assets/images/logos/svg/COOPERATIVA_Logo.svg'

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

function Sobre() {
    const [t] = useTranslation("translations");

    return (
        <>
            <div className="background-container-1">
                <div className="block-container">
                    <div className="title">{t('sobre.title1')}</div>
                    <div className="subtitle">{t('sobre.subtitle1')}</div>
                    <div className="mobile-image-1"></div>
                    <div className="headline">{t('sobre.headline')}</div>
                    <div className="description">{t('sobre.text1')}</div>
                </div>
            </div>

            <div className="background-container-2">
                <div className="block-container">
                    <div className='signatureDiv'>
                        <img alt='logo' src={Logo} width='150px' height='150px' />
                        <p>Onde o trabalho<br></br>encontra a inspiração</p>
                    </div>
                    <p className='hashtags'>#coworkaldeiasdemontanha<br></br>#cooperativacowork</p>
                    <div className='socialmediaDiv'>
                        <div className='fab fa-facebook-f fa-lg' onClick={() => openInNewTab('https://www.facebook.com/aldeiasmontanha')}></div>
                        <div className='fab fa-instagram fa-lg' onClick={() => openInNewTab('https://www.instagram.com/aldeiasdemontanha/')}></div>
                        <div className='fab fa-youtube fa-lg' onClick={() => openInNewTab('https://www.youtube.com/channel/UCQRz7Lvhg-3Kq2q0f2hBEdw')}></div>
                        <div className='fab fa-linkedin-in fa-lg' onClick={() => openInNewTab('http://www.linkedin.com/company/aldeias-de-montanha')}></div>
                        {/* <img alt='logo' src={Logo} width='50px' height='50px' /> */}
                    </div>
                    {/*< div className="title">{t('sobre.title2')}</div>
                    <div className="subtitle">{t('sobre.subtitle2')}</div>
                    <div className="mobile-image-2"></div>
                    <div className="description">{t('sobre.text2')}</div> */}
                </div>
            </div>
        </>
    )
}

export default Sobre;
import React from 'react';
import './css/notFound.css'
import '../../App.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function NotFound() {
    
    const { t } = useTranslation("translations");

    return (
        <div className='notFound-container'>
            <div className='inner-div'>
                <p className='not-found-title'>404</p>
                <p className='not-found-text'>Lost in the woods?</p>
                <Link to='/' className='return-btn'>Return</Link>
            </div>
        </div>

    )
}

export default NotFound

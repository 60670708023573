import { useEffect, useRef } from 'react'
import { ArrowDropDownOutlined as ArrowDown, ArrowDropUpOutlined as ArrowUp } from '@mui/icons-material';

import './css/HoursArrows.css'


export const CheckInTopArrow = ({ disabled }) => {
    const arrowRef = useRef(null)
    const firstItem = document.querySelector(".checkIn-pick-hours-container :nth-child(1)")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            if (!disabled) arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '0px 0px' })

    useEffect(() => {
        if (arrowRef.current) arrowRef.current.classList.toggle("show", !disabled)
    }, [disabled])

    useEffect(() => {
        if (firstItem && arrowRef) observer.observe(firstItem);
        return () => {
            if (firstItem) observer.unobserve(firstItem)
        }
    }, [arrowRef, firstItem])

    return (
        <ArrowUp ref={arrowRef} className='checkIn-arrow-top' />
    )
}


export const CheckInDownArrow = ({ disabled }) => {
    const arrowRef = useRef(null)
    const lastItem = document.querySelector(".checkIn-pick-hours-container :nth-child(24)")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            if (!disabled) arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '0px 0px' })

    useEffect(() => {
        if (arrowRef.current) arrowRef.current.classList.toggle("show", !disabled)
    }, [disabled])

    useEffect(() => {
        if (lastItem && arrowRef) observer.observe(lastItem);
        return () => {
            if (lastItem) observer.unobserve(lastItem)
        }
    }, [arrowRef, lastItem])

    return (
        <ArrowDown ref={arrowRef} className='checkIn-arrow-down' />
    )
}


export const CheckOutTopArrow = ({ disabled }) => {
    const arrowRef = useRef(null)
    const firstItem = document.querySelector(".checkOut-pick-hours-container :nth-child(1)")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            if (!disabled) arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '0px 0px' })

    useEffect(() => {
        if (arrowRef.current) arrowRef.current.classList.toggle("show", !disabled)
    }, [disabled])

    useEffect(() => {
        if (firstItem && arrowRef) observer.observe(firstItem);
        return () => {
            if (firstItem) observer.unobserve(firstItem)
        }
    }, [arrowRef, firstItem])

    return (
        <ArrowUp ref={arrowRef} className='checkOut-arrow-top' />
    )
}


export const CheckOutDownArrow = ({ disabled }) => {
    const arrowRef = useRef(null)
    const lastItem = document.querySelector(".checkOut-pick-hours-container :nth-child(24)")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            if (!disabled) arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '0px 0px' })

    useEffect(() => {
        if (arrowRef.current) arrowRef.current.classList.toggle("show", !disabled)
    }, [disabled])

    useEffect(() => {
        if (lastItem && arrowRef) observer.observe(lastItem);
        return () => {
            if (lastItem) observer.unobserve(lastItem)
        }
    }, [arrowRef, lastItem])

    return (
        <ArrowDown ref={arrowRef} className='checkOut-arrow-down' />
    )
}
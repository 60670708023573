import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Divider } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticTimePicker from '@mui/lab/StaticTimePicker'

import moment from 'moment'

import './HourPickerPopup.css'


function HourPickerPopup(props) {
    const [checkInHours, setCheckInHours] = useState()
    const [checkOutHours, setCheckOutHours] = useState()

    useEffect(() => {
        setCheckInHours(props.checkIn);
        setCheckOutHours(props.checkOut)
    }, [props.checkIn, props.checkOut])

    const { t } = useTranslation('translations')

    return (props.trigger) ? (
        <div className="background-div" >
            <div className="popup">
                <div className="pick-hours-popup-title">
                    <p>{t('hourpicker.title')}</p>
                </div>
                <Divider orientation='horizontal' style={{ width: '100%' }} />
                <div className='hours-main-container'>
                    <div className='checkin-container'>
                        <div className='hours-container'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticTimePicker
                                    displayStaticWrapperAs='mobile'
                                    ampm={false}
                                    minutesStep={5}
                                    value={checkInHours}
                                    className='clock-picker'
                                    toolbarTitle={moment(props.checkIn).format('DD MMMM YYYY')}
                                    onChange={(value) => setCheckInHours(value)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <Divider className='pick-hours-divider' orientation='vertical' />
                    <div className='checkout-container'>
                        <div className='hours-contaienr'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticTimePicker
                                    displayStaticWrapperAs='mobile'
                                    ampm={false}
                                    minutesStep={5}
                                    value={checkOutHours}
                                    toolbarTitle={moment(props.checkOut).format('DD MMMM YYYY')}
                                    onChange={(value) => setCheckOutHours(value)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className='hours-picker-buttons'>
                    <p style={{ marginBottom: '5px' }}>{t('hourpicker.note')}</p>
                    <button onClick={() => props.submit(checkInHours, checkOutHours)}>{t('common.submit')}</button>
                </div>
            </div>
        </div>
    ) : ''
}

export default HourPickerPopup

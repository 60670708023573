import React, { useState } from 'react';
import './Footer.css'
import FooterImage from '../assets/images/logos/footer.png';
import FooterCofinance from '../assets/images/logos/svg/COFINANCIAMENTO_Footer.svg';
import { useTranslation } from "react-i18next"
import TermsAndConditions from '../components/modalPopup/TermsAndConditions'

function Footer() {
    const [t, i18n] = useTranslation('translations');
    const [showTNC, setShowTnc] = useState(false);

    return (
        <section className="footer-container">
            <div className="left-side">
                <img className='left-footer-bar' src={FooterImage} alt="COWORK @ ALDEIAS DE MONTANHA" width='300px' height='auto' />
                <img className="right-footer-bar" src={FooterCofinance} alt="cofinanciamento_bar" width='auto' height='55px' />
            </div>
            <p className='termsAndConditions' onClick={() => setShowTnc(true)}>{t('privacidade')}</p>
            <TermsAndConditions
                trigger={showTNC} language={i18n.language} close={() => setShowTnc(false)} />
        </section>
    )
}

export default Footer;
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import Helmet from 'react-helmet'
import { withTranslation } from 'react-i18next'
import moment from 'moment';
import './Calendar.css';

const disabledDays = new Set([])

class Calendar extends React.Component {
    static defaultProps = {
        numberOfMonths: 1
    };


    constructor(props) {
        super(props);

        this.state = {
            calendarKey: false
        }

        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();
    }

    // componentDidMount() {
    //     this.props.onRef(this);
    // }

    // componentWillUnmount() {
    //     this.props.onRef(undefined);
    // }

    componentDidUpdate(prev) {

        if (prev.people !== this.props.people) {
            this.setState({ calendarKey: !this.state.calendarKey, from: null, to: null }, () => this.props.getDates(this.state.from, this.state.to))
        }
    }

    getInitialState() {
        return {
            from: null,
            to: null,
            enteredTo: null,
        };
    }

    isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    checkIfContainsDisabledDays = (from, to) => {

        const disableDaysArray = Array.from(disabledDays)

        const checkCheckIn = disableDaysArray.some(day => {
            return moment(day).isBetween(from, to, '[]')
        })

        const checkCheckOut = disableDaysArray.some(day => {
            return moment(day).isBetween(from, to, '[]')
        })

        return checkCheckIn || checkCheckOut
    }


    handleDayClick(day, { selected, disabled }) {

        if (disabled) {
            return;
        }

        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.handleResetClick();
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            if (this.checkIfContainsDisabledDays(from, day)) {
                this.handleResetClick()
                this.props.showUnavailablePopup(true)
                return;
            }

            this.setState({
                to: day,
                enteredTo: day,
            });

            this.props.getDates(moment(from).utc().hour(12).minute(30).toDate(), moment(day).utc().hour(12).minute(30).toDate());
        }
    }


    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    }

    handleResetClick() {
        this.setState(this.getInitialState());
        this.props.reset();
    }

    isDayDisabled = (day) => {
        const date = new Date(day);
        date.setUTCHours(7)

        if (moment(day).isSameOrBefore(moment().toDate(), 'day')) return true

        const checkDays = this.props.reservations.some(reservation => {
            if (moment(reservation.day).isSame(date, 'day', '[]')) {
                if (reservation.hours && reservation.hours.length > 0) {
                    return reservation.hours.every(hour => {
                        if (hour.reservation.length > 0) {
                            const seatsOccupied = hour.reservation.reduce((prev, current) => {
                                return {
                                    quantity: prev.quantity + current.quantity
                                }
                            })

                            return seatsOccupied.quantity + this.props.people > this.props.cowork.capacity
                        }
                        return false
                    })
                }
            }
            return false
        })

        if (checkDays) {
            disabledDays.add(moment(date).format('YYYY-MM-DD'))
        } else {
            disabledDays.delete(moment(date).format('YYYY-MM-DD'))
        }

        return checkDays
    }

    updateSelectedMonthOnParent = (e) => {
        this.props.setSelectedMonth(moment(e).hour(8).utc(true))
    }


    render() {
        const { t } = this.props;

        const { from, to, enteredTo } = this.state;
        const modifiers = { start: from, end: enteredTo };
        const selectedDays = [from, { from, to: enteredTo }];

        const initialYear = new Date().getFullYear();
        const initialMonth = new Date().getMonth();

        const months = t('calendar.meses', { returnObjects: true });
        const weekShort = t('calendar.semana_short', { returnObjects: true })
        const weekLong = t('calendar.semana_long', { returnObjects: true })

        return (
            <div className="RangeExample">
                <DayPicker
                    key={this.state.calendarKey}
                    className="Selectable"
                    showOutsideDays
                    onMonthChange={this.updateSelectedMonthOnParent}
                    fromMonth={new Date(initialYear, initialMonth)}
                    numberOfMonths={this.props.numberOfMonths}
                    selectedDays={selectedDays}
                    disabledDays={this.isDayDisabled}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                    locale="pt"
                    months={months}
                    weekdaysLong={weekLong}
                    weekdaysShort={weekShort}
                />
                <Helmet>
                    <style>{`
                            .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #DAD092 !important;
                                color: #9F9B58;
                            }
                            .Selectable .DayPicker-Day {
                                border-radius: 0 !important;
                            }
                            .Selectable .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                            }
                            .Selectable .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                            }
                            `
                    }</style>
                </Helmet>
            </div>
        )
    }
}


export default withTranslation("translations")(Calendar);
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import './css/ReviewContainer.css'
import { useTranslation } from 'react-i18next'
import TnCModalPopup from '../modalPopup/TnCModalPopup';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'

function ReviewContainer(props) {
    const { t } = useTranslation('translations')
    const [showTnc, setShowTnc] = useState(false)
    const [dates, setDates] = useState([])
    const [shareData, setShareData] = useState(true)

    useEffect(() => {
        setDates(props.state.dates.filter(date => date.filled))
    }, [props.state])

    useEffect(() => {
        props.updateParentState('share_data', shareData)
    }, [shareData])

    return (
        <div className='reservation-review-div'>
            <div className='review-info-title-div'>
                <p className='review-title'>{t('reservation.review.title')}</p>
                <p className='review-subtitle'>{t('reservation.review.subtitle')}</p>
            </div>
            <div className='reservation-info-review-div'>
                <div className='reservation-review-info'>
                    <ReviewLabels title='Cowork'>{props.cowork.title}</ReviewLabels>
                    {dates.length > 1 ?
                        <MultipleDatesLabel data={dates} />
                        :
                        <>
                            <ReviewLabels title={t('reservation.review.checkIn')}>{moment(dates[0]?.checkIn).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</ReviewLabels>
                            <ReviewLabels title={t('reservation.review.checkOut')}>{moment(dates[0]?.checkOut).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</ReviewLabels>
                            <ReviewLabels title={t('reservation.review.people')}>{dates[0]?.people}</ReviewLabels>
                        </>
                    }
                    <ReviewLabels title={t('reservation.review.location')}>{`${props.cowork.address}, ${props.cowork.coordinates}`}</ReviewLabels>
                    <ReviewLabels title={t('reservation.review.note.title')}>{t('reservation.review.note.text')}</ReviewLabels>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ReviewLabels title={t('reservation.review.shareNote.title')}>{t('reservation.review.shareNote.text')}</ReviewLabels>
                        <FormControl sx={{marginTop: '-15px'}}>
                            <RadioGroup
                                row
                                name='shareData'
                                onChange={e => setShareData(e.target.value === 'true' ? true : false)}
                                value={shareData}
                            >
                                <FormControlLabel className='reservation-share-radio' value={true} control={<Radio />} label={t('reservation.review.shareNote.yes')} />
                                <FormControlLabel className='reservation-share-radio' value={false} control={<Radio />} label={t('reservation.review.shareNote.no')} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div className='reservation-review-action'>
                    <div className="reservation-checkbox-div">
                        <input type="checkbox" name="conditions" id="terms-checkbox"
                            onChange={(event) => props.updateParentState('tnc', event.target.checked)}
                        />
                        <label className='review-tnc-label' htmlFor="terms-checkbox">{t('reservation.actions.tnc.first')}
                            <span onClick={() => setShowTnc(true)}>
                                <label style={{ cursor: 'pointer', textDecoration: 'underline' }}>{t('reservation.actions.tnc.second')}</label>
                            </span>
                        </label>
                    </div>
                    <button className="review-submit-btn" onClick={props.submit} disabled={!props.state.tnc || !props.state.formIsValid}>{t('reservation.actions.button')}</button>
                </div>
            </div>
            <TnCModalPopup
                trigger={showTnc}
                data={props.cowork}
                close={() => setShowTnc(false)}
            />
        </div>
    )
}

export default ReviewContainer

const ReviewLabels = ({ title, children }) => {
    return (
        <div className='review-labels-div'>
            <p className='review-labels-title'>{title}</p>
            <p className='review-labels-text'>{children}</p>
        </div>
    )
}

const MultipleDatesLabel = (data) => {
    const { t } = useTranslation('translations')

    return (
        <div className='review-multiple-div'>
            <div className='review-multiple-labels'>
                <p className='review-labels-title'>{t('reservation.review.checkIn')}</p>
                <p className='review-labels-title'>{t('reservation.review.checkOut')}</p>
                <p className='review-labels-title'>{t('reservation.review.nPeople')}</p>
            </div>
            <div className='review-multiple-values-div'>
                {data.data.map((date, index) => {
                    return (
                        <div key={index} className='review-multiple-values'>
                            <p className='review-labels-text-multiple'>{moment(date.checkIn).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</p>
                            <p className='review-labels-text-multiple'>{moment(date.checkOut).utc(false).format('dddd, DD MMMM YYYY, HH:mm')}</p>
                            <p className='review-labels-text-multiple'>{date.people}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
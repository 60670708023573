import React from 'react'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from "react-i18next"
import './tncmodalpopup.css'
import '../../App.css'

function TnCModalPopup(props) {

    const { t } = useTranslation("translations");

    return (props.trigger) ? (
        <div className="tnc-background-div" >
            <div className="tnc-popup">
                <div className="tnc-navigation-div">
                    <p>{t('privacidade')}</p>
                    <div className="tnc-btn-div">
                        <div data-tip data-for='close-btn' className="fas fa-window-close" onClick={props.close}></div>
                    </div>
                </div>
                <div className="content-div">
                    {props.language === 'pt' ? <TncPortugues /> : <TncIngles />}
                </div>
            </div>
            <ReactTooltip id="close-btn" effect="solid">
                <span>{t('tooltips.fechar')}</span>
            </ReactTooltip>
        </div>
    ) : ''
}


const TncIngles = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '35px', marginBottom: '25px' }}>POLÍTICA DE PRIVACIDADE</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`É política da ADIRAM - Associação para o Desenvolvimento Integrado da Rede da Cooperativa respeitar a privacidade dos utilizadores em relação a qualquer informação que possamos pedir no website da Cooperativa, e outros websites que possuímos e operamos.

                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.

                O atual website não tem registo de utilizador pelo que não são guardados quaisquer dados. Apenas usa a sessão para guardar os locais favoritos do website, adicionados pelo utilizador, para efeitos de cálculo do itinerário personalizado no mapa interativo. Esta sessão do browser é temporária e o   utilizador   pode   limpar   a   sessão   a   qualquer   momento,   não   ficando guardado nenhuma informação em base de dados.

                O nosso website  pode ter links  para websites externos  que não  são operados   por   nós.  Esteja  ciente   de  que  não  temos  controle   sobre  o conteúdo   e   práticas   desses   websites   e   não   podemos   aceitar responsabilidade por suas respetivas políticas de privacidade.

                O uso continuado de nosso website será considerado como aceitação de nossas práticas em torno de privacidade e termos de uso.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>Compromisso do Utilizador</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`O utilizador compromete-se a fazer uso adequado dos conteúdos e da informação que a ADIRAM oferece no website e com caráter enunciativo, mas não limitativo:
                
                A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;

                B) Não difundir propaganda ou conteúdo de natureza racista, xenófoba, ou casas de apostas, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;

                C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da ADIRAM, de seus fornecedores ou terceiros, para introduzir ou   disseminar   vírus   informáticos   ou   quaisquer   outros   sistemas   de hardware ou software que sejam capazes de causar danos anteriormente mencionados.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>1. Termos de uso</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Ao aceder ao website da Cooperativa, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis  e concorda que é responsável pelo cumprimento de todas as leis aplicáveis. Os materiais contidos neste website são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.`}</p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>2. Uso de Licença</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`É concedida permissão para descarregar uma cópia dos materiais (informações ou software) no website da Cooperativa, para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: 
                
                1. Modificar ou copiar os materiais; 
                2. Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); 
                3. Tentar descompilar ou fazer engenharia reversa de qualquer software contido no website da Cooperativa; 
                4. Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais;
                5. Transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.

                Esta licença será automaticamente rescindida se violar alguma dessas restrições e poderá ser rescindida pela ADIRAM a qualquer momento.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>3. Isenção de responsabilidade</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Os materiais no website da Cooperativa são fornecidos 'como estão'. A ADIRAM não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.

                Além disso, a ADIRAM não garante ou faz qualquer representação relativa à  recisão, aos resultados prováveis ou à confiabilidade do uso dos materiais no seu website ou de outra forma relacionado a esses materiais ou em websites vinculados a este website.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>4. Limitações</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Em nenhum caso a ADIRAM ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais do website da Cooperativa, mesmo que a ADIRAM ou um representante autorizado da ADIRAM tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes ou incidentais, essas limitações podem não se aplicar a você.`}</p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>5. Precisão dos materiais</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Os materiais exibidos  no website da Cooperativa  podem incluir erros técnicos, tipográficos ou fotográficos. A ADIRAM não garante que qualquer material no seu website seja preciso, completo ou atual. A ADIRAM pode fazer alterações nos materiais contidos no seu website a qualquer momento, sem aviso prévio; no entanto, não se compromete a atualizar os materiais.`}</p>

            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>6. Links</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`A ADIRAM não analisou todos os websites vinculados ao seu website e não é responsável pelo conteúdo de nenhum website vinculado. A inclusão de qualquer link não implica uma subscrição pela ADIRAM do website. O uso de qualquer website vinculado é por conta e risco do usuário.
                
                Modificações:
                A ADIRAM pode rever estes termos de serviço do website a qualquer momento, sem aviso prévio. Ao usar este website, você concorda em ficar vinculado à versão atual desses termos de serviço.
                
                Lei aplicável:
                Estes termos e condições são regidos e interpretados de acordo com as leis   portuguesas,   estando   submetidos   irrevogavelmente   à   jurisdição exclusiva dos tribunais de Portugal.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>7. Cookies</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Como é prática comum em quase todos os sites profissionais, este site usa   cookies,   que   são   pequenos   arquivos   descarregados   no   seu computador, para melhorar a sua experiência. Esta página descreve quais as informações  eles  recolhem,  como  as  usamos  e por  que às  vezes precisamos armazenar esses cookies. Também compartilharemos como pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Como usamos os cookies?</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que deixe todos os cookies se não tiver a certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que usa.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Desativar cookies</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que não desative os cookies.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Cookies que definimos:</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.

                Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como usa o site e como podemos melhorar sua experiência. 
                
                Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.

                As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar a produção de conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar a plataforma.

                Periodicamente, testamos novos recursos e fazemos alterações na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.`}
            </p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Mais Informações</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`A ADIRAM atua como responsável no tratamento dos ficheiros de dados cuja titularidade corresponde à ADIRAM.  O Encarregado de Proteção de Dados pode ser contactado através de email inovacao@aldeiasdemontanha.pt ou por carta registadas, com aviso de receção, para Centro Dinamizador da Rede de Aldeias de Montanha, Largo Dr. Borges Pires, 6270-494 Seia.
            
            Data da última atualização da política de privacidade: 23 de Junho de 2021`}
            </p>
        </div>
    )
}

const TncPortugues = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '35px', marginBottom: '25px' }}>POLÍTICA DE PRIVACIDADE</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`É política da ADIRAM - Associação para o Desenvolvimento Integrado da Rede da Cooperativa respeitar a privacidade dos utilizadores em relação a qualquer informação que possamos pedir no website da Cooperativa, e outros websites que possuímos e operamos.

                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento.

                O atual website não tem registo de utilizador pelo que não são guardados quaisquer dados. Apenas usa a sessão para guardar os locais favoritos do website, adicionados pelo utilizador, para efeitos de cálculo do itinerário personalizado no mapa interativo. Esta sessão do browser é temporária e o   utilizador   pode   limpar   a   sessão   a   qualquer   momento,   não   ficando guardado nenhuma informação em base de dados.

                O nosso website  pode ter links  para websites externos  que não  são operados   por   nós.  Esteja  ciente   de  que  não  temos  controle   sobre  o conteúdo   e   práticas   desses   websites   e   não   podemos   aceitar responsabilidade por suas respetivas políticas de privacidade.

                O uso continuado de nosso website será considerado como aceitação de nossas práticas em torno de privacidade e termos de uso.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>Compromisso do Utilizador</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`O utilizador compromete-se a fazer uso adequado dos conteúdos e da informação que a ADIRAM oferece no website e com caráter enunciativo, mas não limitativo:
                
                A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;

                B) Não difundir propaganda ou conteúdo de natureza racista, xenófoba, ou casas de apostas, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;

                C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da ADIRAM, de seus fornecedores ou terceiros, para introduzir ou   disseminar   vírus   informáticos   ou   quaisquer   outros   sistemas   de hardware ou software que sejam capazes de causar danos anteriormente mencionados.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>1. Termos de uso</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Ao aceder ao website da Cooperativa, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis  e concorda que é responsável pelo cumprimento de todas as leis aplicáveis. Os materiais contidos neste website são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.`}</p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>2. Uso de Licença</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`É concedida permissão para descarregar uma cópia dos materiais (informações ou software) no website da Cooperativa, para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: 
                
                1. Modificar ou copiar os materiais; 
                2. Usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); 
                3. Tentar descompilar ou fazer engenharia reversa de qualquer software contido no website da Cooperativa; 
                4. Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais;
                5. Transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.

                Esta licença será automaticamente rescindida se violar alguma dessas restrições e poderá ser rescindida pela ADIRAM a qualquer momento.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>3. Isenção de responsabilidade</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Os materiais no website da Cooperativa são fornecidos 'como estão'. A ADIRAM não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.

                Além disso, a ADIRAM não garante ou faz qualquer representação relativa à  recisão, aos resultados prováveis ou à confiabilidade do uso dos materiais no seu website ou de outra forma relacionado a esses materiais ou em websites vinculados a este website.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>4. Limitações</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Em nenhum caso a ADIRAM ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais do website da Cooperativa, mesmo que a ADIRAM ou um representante autorizado da ADIRAM tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes ou incidentais, essas limitações podem não se aplicar a você.`}</p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>5. Precisão dos materiais</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Os materiais exibidos  no website da Cooperativa  podem incluir erros técnicos, tipográficos ou fotográficos. A ADIRAM não garante que qualquer material no seu website seja preciso, completo ou atual. A ADIRAM pode fazer alterações nos materiais contidos no seu website a qualquer momento, sem aviso prévio; no entanto, não se compromete a atualizar os materiais.`}</p>

            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>6. Links</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`A ADIRAM não analisou todos os websites vinculados ao seu website e não é responsável pelo conteúdo de nenhum website vinculado. A inclusão de qualquer link não implica uma subscrição pela ADIRAM do website. O uso de qualquer website vinculado é por conta e risco do usuário.
                
                Modificações:
                A ADIRAM pode rever estes termos de serviço do website a qualquer momento, sem aviso prévio. Ao usar este website, você concorda em ficar vinculado à versão atual desses termos de serviço.
                
                Lei aplicável:
                Estes termos e condições são regidos e interpretados de acordo com as leis   portuguesas,   estando   submetidos   irrevogavelmente   à   jurisdição exclusiva dos tribunais de Portugal.`}
            </p>
            
            <p style={{ fontSize: '25px', marginBottom: '25px', fontWeight: '500' }}>7. Cookies</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Como é prática comum em quase todos os sites profissionais, este site usa   cookies,   que   são   pequenos   arquivos   descarregados   no   seu computador, para melhorar a sua experiência. Esta página descreve quais as informações  eles  recolhem,  como  as  usamos  e por  que às  vezes precisamos armazenar esses cookies. Também compartilharemos como pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Como usamos os cookies?</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que deixe todos os cookies se não tiver a certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que usa.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Desativar cookies</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que não desative os cookies.`}</p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Cookies que definimos:</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.

                Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como usa o site e como podemos melhorar sua experiência. 
                
                Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.

                As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar a produção de conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar a plataforma.

                Periodicamente, testamos novos recursos e fazemos alterações na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.`}
            </p>
            <p style={{ fontSize: '20px', marginBottom: '25px', fontWeight: '600' }}>Mais Informações</p>
            <p style={{ whiteSpace: 'pre-line', marginBottom: '50px' }}>{`A ADIRAM atua como responsável no tratamento dos ficheiros de dados cuja titularidade corresponde à ADIRAM.  O Encarregado de Proteção de Dados pode ser contactado através de email inovacao@aldeiasdemontanha.pt ou por carta registadas, com aviso de receção, para Centro Dinamizador da Rede de Aldeias de Montanha, Largo Dr. Borges Pires, 6270-494 Seia.
            
            Data da última atualização da política de privacidade: 23 de Junho de 2021`}
            </p>
        </div>
    )
}

export default TnCModalPopup

import axios from 'axios'
import { paix } from 'paix'

const shop_items_PT = { mobiliario: [], iluminaria: [], tapecaria: [], decoracao: [] }
const shop_items_EN = { mobiliario: [], iluminaria: [], tapecaria: [], decoracao: [] }

function fetchData() {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/api/store_items`, { headers: { 'apikey': process.env.REACT_APP_API_KEY_FO } })
        .then((res) => {
            shop_items_PT.mobiliario = res.data.filter(item => item.category === 'mobiliario')
            shop_items_PT.iluminaria = res.data.filter(item => item.category === 'iluminaria')
            shop_items_PT.tapecaria = res.data.filter(item => item.category === 'tapecaria')
            shop_items_PT.decoracao = res.data.filter(item => item.category === 'decoracao')
            shop_items_EN.mobiliario = res.data.filter(item => item.category === 'mobiliario')
            shop_items_EN.iluminaria = res.data.filter(item => item.category === 'iluminaria')
            shop_items_EN.tapecaria = res.data.filter(item => item.category === 'tapecaria')
            shop_items_EN.decoracao = res.data.filter(item => item.category === 'decoracao')
        }, (err) => {
            console.log(err)
        });
}

const transformData = () => {
    const PT_replacement = { name_PT: 'name', materials_PT: 'materials', description_PT: 'description' };
    const EN_replacement = { name_EN: 'name', materials_EN: 'materials', description_EN: 'description' }

    shop_items_PT.mobiliario.forEach((element, index) => {
        shop_items_PT.mobiliario[index] = (({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_PT.mobiliario[index] = paix(shop_items_PT.mobiliario[index], PT_replacement)
    });

    shop_items_PT.iluminaria.forEach((element, index) => {
        shop_items_PT.iluminaria[index] = (({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_PT.iluminaria[index] = paix(shop_items_PT.iluminaria[index], PT_replacement)
    });

    shop_items_PT.tapecaria.forEach((element, index) => {
        shop_items_PT.tapecaria[index] = (({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_PT.tapecaria[index] = paix(shop_items_PT.tapecaria[index], PT_replacement)
    });

    shop_items_PT.decoracao.forEach((element, index) => {
        shop_items_PT.decoracao[index] = (({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_PT, dimensions, materials_PT, origin, delivery_time, price, description_PT, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_PT.decoracao[index] = paix(shop_items_PT.decoracao[index], PT_replacement)
    });

    shop_items_EN.mobiliario.forEach((element, index) => {
        shop_items_EN.mobiliario[index] = (({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_EN.mobiliario[index] = paix(shop_items_EN.mobiliario[index], EN_replacement)
    });

    shop_items_EN.iluminaria.forEach((element, index) => {
        shop_items_EN.iluminaria[index] = (({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_EN.iluminaria[index] = paix(shop_items_EN.iluminaria[index], EN_replacement)
    });

    shop_items_EN.tapecaria.forEach((element, index) => {
        shop_items_EN.tapecaria[index] = (({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_EN, dimensions, materials_EN, origin, delivery_time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_EN.tapecaria[index] = paix(shop_items_EN.tapecaria[index], EN_replacement)
    });

    shop_items_EN.decoracao.forEach((element, index) => {
        shop_items_EN.decoracao[index] = (({ id, name_EN, dimensions, materials_EN, origin, delivery_Time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }) => ({ id, name_EN, dimensions, materials_EN, origin, delivery_Time, price, description_EN, portrait, sliderImage1, sliderImage2, sliderImage3, available }))(element);
        shop_items_EN.decoracao[index] = paix(shop_items_EN.decoracao[index], EN_replacement)
    });
}

fetchData()
    .then(() => {
        transformData();
        MobiliarioPT.items = shop_items_PT.mobiliario;
        MobiliarioEN.items = shop_items_EN.mobiliario;
        IluminariaPT.items = shop_items_PT.iluminaria;
        IluminariaEN.items = shop_items_EN.iluminaria;
        TapecariaPT.items = shop_items_PT.tapecaria;
        TapecariaEN.items = shop_items_EN.tapecaria;
        DecoracaoPT.items = shop_items_PT.decoracao;
        DecoracaoEN.items = shop_items_EN.decoracao;

    })

export const MobiliarioPT = {
    title: 'Mobiliário',
    description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const MobiliarioEN = {
    title: 'Forniture',
    description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const IluminariaPT = {
    title: 'Mobiliário',
    description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const IluminariaEN = {
    title: 'Lighting',
    description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const TapecariaPT = {
    title: 'Tapeçaria',
    description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const TapecariaEN = {
    title: 'Tapestry',
    description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const DecoracaoPT = {
    title: 'Decoração',
    description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


export const DecoracaoEN = {
    title: 'Decoration',
    description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
    items: []
}


/* export const mobiliarioPT = [

    {
        title: 'Mobiliário',
        description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '1',
                name: "Estante",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '2',
                name: "Lavatório",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '3',
                name: "Armário",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '4',
                name: "Candeeiro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '5',
                name: "Mesa Centro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '6',
                name: "Mesa Apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '7',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '8',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '9',
                name: "Candeeiro de Pé",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '10',
                name: "Mesa de apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const mobiliarioEN = [

    {
        title: 'Forniture',
        description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '1',
                name: "Bookcase",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 weeks",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '2',
                name: "Washbasin",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '3',
                name: "Cabinet",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '4',
                name: "Ceiling Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '5',
                name: "Center Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '6',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '7',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '8',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '9',
                name: "Floor Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '10',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const tapecariaPT = [

    {
        title: 'Tapeçaria',
        description: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '11',
                name: "Estante",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '12',
                name: "Lavatório",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '13',
                name: "Armário",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '14',
                name: "Candeeiro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '15',
                name: "Mesa Centro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '16',
                name: "Mesa Apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '17',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '18',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '19',
                name: "Candeeiro de Pé",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '20',
                name: "Mesa de apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const tapecariaEN = [

    {
        title: 'Tapestry',
        description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '11',
                name: "Bookcase",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 weeks",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '12',
                name: "Washbasin",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '13',
                name: "Cabinet",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '14',
                name: "Ceiling Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '15',
                name: "Center Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '16',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '17',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '18',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '19',
                name: "Floor Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '20',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const decoracaoPT = [

    {
        title: 'Decoração',
        description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '21',
                name: "Estante",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '22',
                name: "Lavatório",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '23',
                name: "Armário",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '24',
                name: "Candeeiro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '25',
                name: "Mesa Centro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '26',
                name: "Mesa Apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '27',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '28',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '29',
                name: "Candeeiro de Pé",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '30',
                name: "Mesa de apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Portugues: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const decoracaoEN = [

    {
        title: 'Decoration',
        description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '21',
                name: "Bookcase",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 weeks",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '22',
                name: "Washbasin",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '23',
                name: "Cabinet",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '24',
                name: "Ceiling Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '25',
                name: "Center Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '26',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '27',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '28',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '29',
                name: "Floor Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '30',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const iluminariaPT = [

    {
        title: 'Iluminária',
        description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '31',
                name: "Estante",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '32',
                name: "Lavatório",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '33',
                name: "Armário",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '34',
                name: "Candeeiro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '35',
                name: "Mesa Centro",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '36',
                name: "Mesa Apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '37',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '38',
                name: "Cadeira",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '39',
                name: "Candeeiro de Pé",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '40',
                name: "Mesa de apoio",
                dimensions: "A x B x C cm",
                materials: "Português: Laoreet dolore magna aliquam er",
                origin: "Português: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `Português: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
]

export const iluminariaEN = [

    {
        title: 'Lighting',
        description: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
        items: [
            {
                id: '31',
                name: "Bookcase",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 weeks",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image10,
                available: true
            },

            {
                id: '32',
                name: "Washbasin",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image5,
                available: true
            },

            {
                id: '33',
                name: "Cabinet",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image4,
                available: true
            },

            {
                id: '34',
                name: "Ceiling Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image1,
                available: true
            },

            {
                id: '35',
                name: "Center Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image9,
                available: true
            },

            {
                id: '36',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image6,
                available: true
            },

            {
                id: '37',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image3,
                available: true
            },

            {
                id: '38',
                name: "Chair",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image2,
                available: true
            },

            {
                id: '39',
                name: "Floor Lamp",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image8,
                available: true
            },

            {
                id: '40',
                name: "Support Table",
                dimensions: "A x B x C cm",
                materials: "English: Laoreet dolore magna aliquam er",
                origin: "English: Nt ut laoreet dolore",
                deliveryTime: "3 Semanaas",
                price: "350€",
                condition: `English: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

                Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
                image: image7,
                available: true
            },
        ]
    }
] */
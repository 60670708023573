import React, { useState } from 'react'
import { getGroupedAmenities } from '../data/Amenities'
import { useTranslation } from 'react-i18next'
import { Divider, ClickAwayListener } from '@mui/material'
import { Search } from '@mui/icons-material'

import './css/InfoContainer.css'

function InfoContainer({ cowork }) {
	const [zoom, setZoom] = useState(false)
	const [mapLoading, setMapLoading] = useState(true)
	const coworkCoord = cowork.coordinates.split(',')
	const coworkAmenities = getGroupedAmenities(cowork.amenities)
	const { i18n } = useTranslation('translations')

	return (
		<div className='info-main-container'>
			<div className='plant-map-div'>
				<ClickAwayListener onClickAway={() => setZoom(false)}>
					<div className={`cowork-plant${zoom ? ' zoom' : ''}`}>
						<Search className='plant-zoom-icon' htmlColor='white' onClick={() => setZoom(!zoom)} />
						<img className='cowork-plant-img' alt='cowork-plant' width='auto' height='100%' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${cowork.blueprint}`} />
					</div>
				</ClickAwayListener>
				<div className='cowork-map-div'>
					{mapLoading &&
						<div className='info-main-map-loading'>
							<p>Loading map, please wait...</p>
						</div>
					}
					<iframe
						title='map'
						src={`https://maps.google.com/maps?q=${coworkCoord[0]},${coworkCoord[1]}&z=17&output=embed`}
						width="100%"
						height="100%"
						style={{ border: '0px', borderRadius: '15px' }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						onLoad={() => setMapLoading(false)}
					/>
					<p className='info-map-label'>{cowork.address}</p>
				</div>
			</div>
			<Divider orientation='horizontal' className='info-container-divider' />
			<div className='cowork-properties-div'>
				{Object.keys(coworkAmenities).map(group => {
					return (
						<div className='amenities-group-div' key={group}>
							<p className='amenities-group-title'>{i18n.language === 'pt' ? group.split('/')[1] : group.split('/')[0]}</p>
							<div className='amenities-group-inner-div'>
								{coworkAmenities[group].map(amenity => {
									return (
										<CoworkFacilities key={amenity.namePT} trait={amenity} i18n={i18n} />
									)
								})}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default InfoContainer


const CoworkFacilities = ({ trait, i18n }) => {
	return (
		<div className='amenities-div'>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img alt='amenity icon' src={trait.icon} width='25px' height='25px' />
				<p style={{ marginLeft: '10px', letterSpacing: '2px', fontSize: '14px', color: 'gray' }}>{i18n.language === 'pt' ? trait.namePT : trait.nameEN}</p>
			</div>
		</div>

	)
}
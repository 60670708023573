import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

import blueprint from '../../assets/images/espaçosdetalhes/PLANTA-SALA.png'

import './css/SeatsContainer.css'

const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: '#61634f'
	},
	'& .MuiRating-iconHover': {
		color: '#61634f'
	}
})


function SeatsContainer({ people, updateParentState }) {
	const [seats, setSeats] = useState(new Set([]))

	const handleSeatsInput = (seat) => {
		if (seats.has(seat)) {
			setSeats(prev => new Set([...prev].filter(x => x !== seat)))
			return;
		}

		if (seats.size + 1 >= people) window.scrollBy({ top: 600, behavior: 'smooth' })

		if (seats.size >= people) return;
		
		setSeats(prev => new Set(prev.add(seat)))
	}

	useEffect(() => {
		updateParentState('seats', seats)
	}, [seats])

	const checkIfSeatSelected = (seat) => {
		return seats.has(seat)
	}

	return (
		<div className='seats-main-container'>
			<div className='seats-info'>
				<div className='seats-info-title-div'>
					<p className='seats-title'>Pick your seats</p>
					<p className='seats-subtitle'>Use the blueprint on your right side to pick your seats</p>
				</div>
				<div className='seats-persons-display'>
					<p className='seats-subtitle'>{`You can pick ${people} seats`}</p>
					<div className='seats-pick-icons'>
						<StyledRating
							name='people-selection'
							readOnly
							sx={{ flexWrap: 'wrap', justifyContent: 'center', marginBottom: '5px' }}
							value={seats.size}
							icon={<PersonIcon fontSize='large' />}
							emptyIcon={<PersonOutlineIcon fontSize='large' />}
							max={people}
						/>
						<p className='seats-subtitle'>{`${seats.size} seats picked`}</p>
					</div>
				</div>
				<p className='seats-bottom-text'>If you skip this step, random seats will be assigned</p>
			</div>
			<div className='seats-blueprint'>
				<img alt='room-blueprint' src={blueprint} height='400px' width='auto' />
				<button style={checkIfSeatSelected('seat 1') ? { backgroundColor:'#d2bb53'} : {}} onClick={() => handleSeatsInput('seat 1')}>seat 1</button>
				<button style={checkIfSeatSelected('seat 2') ? { backgroundColor:'#d2bb53'} : {}} onClick={() => handleSeatsInput('seat 2')}>seat 2</button>
				<button style={checkIfSeatSelected('seat 3') ? { backgroundColor:'#d2bb53'} : {}} onClick={() => handleSeatsInput('seat 3')}>seat 3</button>
				<button style={checkIfSeatSelected('seat 4') ? { backgroundColor:'#d2bb53'} : {}} onClick={() => handleSeatsInput('seat 4')}>seat 4</button>
			</div>
		</div>
	)
}

export default SeatsContainer
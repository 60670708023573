import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Feedback from './components/pages/Feedback'

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <Switch>
        <Route path='/feedback' exact component={Feedback} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);


import React, { useState, useEffect } from 'react'
import './Navbar.css';
import { NavLink } from 'react-router-dom'
import logo_svg from '../assets/images/logos/svg/COOPERATIVA_Logo.svg'
import logoAM_svg from '../assets/images/logos/svg/AM_Logo.svg'
import { useTranslation } from "react-i18next"
import { Sling as Hamburger } from 'hamburger-react'
import { debounce } from '../utils'

function Navbar() {
    const [click, setClick] = useState(false);
    const [lastScrollPos, setLastScrollPos] = useState(0)
    const [showNavbar, setShowNavbar] = useState(true)
    const [t, i18n] = useTranslation('translations');
    const [ptFocused, setPtFocused] = useState(false);
    const [enFocused, setEnFocused] = useState(false);

    const defaultLanguage = window.navigator.userLanguage || window.navigator.language;

    useEffect(() => {
        handleChangeLanguage(defaultLanguage);
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollPos])

    const handleScroll = () => {
        debounceScroll()
    }

    const debounceScroll = debounce(() => {
        if (typeof window !== 'undefined') {
            if (window.scrollY < 120) {
                setShowNavbar(true)
            } else {
                setShowNavbar(window.scrollY < lastScrollPos)
            }
            setLastScrollPos(window.scrollY)
        }
    }, 100)


    const handleChangeLanguage = language => {
        i18n.changeLanguage(language);

        if (language === 'pt') {
            setPtFocused(true);
            setEnFocused(false);
            return;
        }
        setEnFocused(true);
        setPtFocused(false);
    }

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <nav className={`navbar${showNavbar ? ' show' : ' hide'}`}>
                <div className="navbar-logos">
                    <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img alt="logo" src={logo_svg} className="logo" />
                    </NavLink>
                    <img alt="" src={logoAM_svg} className="logo-2" onClick={() => openInNewTab('https://www.aldeiasdemontanha.pt/pt/')} />
                </div>
                <div className="navbar-container">
                    <div className="menu-icon" onClick={handleClick}>
                        <Hamburger toggled={click} toggle={setClick} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-link" to="/sobre" className="nav-links" onClick={closeMobileMenu}>
                                {t('navbar.sobre')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-link" to="/espacos" className="nav-links" onClick={closeMobileMenu}>
                                {t('navbar.espaços')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-link" to="/parcerias" className="nav-links" onClick={closeMobileMenu}>
                                {t('navbar.parcerias')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-link" to="/contactos" className="nav-links" onClick={closeMobileMenu}>
                                {t('navbar.contactos')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-link" to="/loja" className="nav-links" onClick={closeMobileMenu}>
                                {t("navbar.loja")}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active-reservar" to="/reservar" className="nav-links-reservar" onClick={closeMobileMenu}>
                                {t('navbar.reservar')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <div className="navbar-language-changer">
                                <div className={`language-btn ${ptFocused ? 'focused' : 'unfocused'}`} onClick={() => handleChangeLanguage('pt')}>PT</div>
                                |
                                <div className={`language-btn ${enFocused ? 'focused' : 'unfocused'}`} onClick={() => handleChangeLanguage('en-GB')}>EN</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar

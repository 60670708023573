import React from 'react'
import ContactForm from '../Form/ContactForm'
import './css/Contactos.css'
import '../../App.css';
import { useTranslation } from 'react-i18next'


function Contactos() {
    const {t} = useTranslation("translations");

    return (
        <>
            <div className="contactos-background-container">
                <div className="contactos-block-container">
                    <div className="contactos-title">{t('contactos.title')}</div>
                    <div className="contactos-text">{t('contactos.text')}</div>
                    <ContactForm />
                </div>
            </div>
        </>
    )
}

export default Contactos;